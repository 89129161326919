<h4>Evaluaciones</h4>

<mat-card class="p-0">
    <mat-card-content class="p-0">
        <ngx-datatable class="material ml-0 mr-0" [rows]="evaluations" columnMode="force" [headerHeight]="50"
            footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

            <ngx-datatable-column name="Grupo" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.group?.name">{{ row?.group?.name }} </span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Nombre" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.name">{{ row?.name }} </span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Porcentaje" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.value">{{ row?.value }} % </span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Fecha de publicación" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.published_at">{{ row?.published_at | date: "dd/MM/yy h:mm:ss" }} </span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Acción">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button *ngIf="row?.selected" class="button-7 bg-red noselect" type="button"
                        style="margin-right: 0.5rem;" disabled>Seleccionado</button>

                    <button *ngIf="!row?.selected" class="button-7 bg-blue" type="button" style="margin-right: 0.5rem;"
                        (click)="seleccionar(row)">Seleccionar</button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>

                    <div class="full-width paginator-container">
                        <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
                            aria-label="Select page" (page)="handlePage($event)">
                        </mat-paginator>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>



    </mat-card-content>
</mat-card>