<mat-card style="padding: 2rem;">
    <h3>Reporte - Matrículas</h3>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 2rem">
        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Ruta/Paquete" name="learning_package_id" [(ngModel)]="learning_package_id"
                    matNativeControl (valueChange)="changeLearningPackage($event)">
                    <mat-option [value]="0">
                        Todos
                    </mat-option>
                    <mat-option [value]="learning_package?.id" *ngFor="let learning_package of learning_packages">
                        {{ getTitle(learning_package) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Curso" name="course_id" [(ngModel)]="course_id" matNativeControl
                    (valueChange)="changeCourse($event)">
                    <mat-option [value]="0">
                        Todos
                    </mat-option>
                    <mat-option [value]="course?.id" *ngFor="let course of courses">
                        {{course?.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Grupo" name="group_id" [(ngModel)]="group_id" matNativeControl>
                    <mat-option [value]="group?.id" *ngFor="let group of groups">
                        {{group?.name}} {{group.code ? "("+group.code+")" : ""}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="section-btn mt-16">
        <button mat-stroked-button type="button" class="btn-warning" (click)="downloadSubscriptions()"
            *ngIf="ableDownload">
            Descarga excel
        </button>
        <button mat-stroked-button type="button" class="btn-warning" (click)="downloadUsers()" *ngIf="ableDownload">
            Descarga todos los usuarios
        </button>
        <div>
            <button mat-stroked-button type="button" class="btn-danger mr-4" (click)="clearSubscriptionFilters()">
                Limpiar
            </button>
            <button mat-stroked-button type="button" class="btn-primary" (click)="getSubscriptions()">
                Mostrar
            </button>
        </div>
    </div>

    <mat-card class="p-0 tabresults">
        <mat-card-content class="p-0">
            <ngx-datatable class="material ml-0 mr-0" [rows]="subscriptions" [columnMode]="'force'" [headerHeight]="50"
                footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

                <ngx-datatable-column name="Nombre" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.user.first_name }} {{ row?.user.last_name }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="DNI" [flexGrow]="2">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.user.document_number }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Correo" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.user.email }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Celular" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.user.phone }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Clasificación" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.classification | classification }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Ruta/Paquete" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.learning_package?.title }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Curso" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.course?.title }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Grupo" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.group?.code }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Fechas" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        Inicio: {{ row?.group?.date_start?.split(" ")[0] | date:"dd/MM/YYYY" }}
                        <br>
                        {{ (row?.group?.date_until)? 'Fin:': '' }}
                        {{ row?.group?.date_until?.split(" ")[0] | date:"dd/MM/YYYY" }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="F. Registro" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.created_at | date:"dd/MM/YYYY HH:mm"}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="F. Finalización" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.finished_at | date:"dd/MM/YYYY HH:mm" }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="T. Sesión" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ toHoursAndMinutes(row?.time_session) }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Ú. Conexión" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.last_connection_at | date:"dd/MM/YYYY HH:mm" }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Matrícula" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        S/{{ row?.amount }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Estado" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ getStatus(row) }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                        <div class="full-width paginator-container">
                            <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
                                aria-label="Select page" (page)="handlePage($event)">
                            </mat-paginator>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </mat-card-content>
    </mat-card>
</mat-card>