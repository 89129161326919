<h4>Cuotas</h4>

    <ngx-datatable class="material ml-0 mr-0" [rows]="billings" columnMode="force" [headerHeight]="50"
        footerHeight="auto" [scrollbarH]="true" [limit]="8" [rowHeight]="50">

    
    
        <ngx-datatable-column name="Monto">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.amount }}
            </ng-template>
        </ngx-datatable-column>
    
        <ngx-datatable-column name="Número de pagos">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.number_of_payments }}
            </ng-template>
        </ngx-datatable-column>
    
    
        <ngx-datatable-column name="Acciones" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button type="button"
                    [ngClass]="selectedBilling?.id == row?.id ? 'button-7 bg-red mr-8' : 'button-7 bg-blue mr-8'"
                    (click)="handleSelectBilling(row)">    
                    {{selectedBilling?.id == row?.id ?'Seleccionado':'Seleccionar'}}    
                </button>    
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
