<form style="padding: 1rem; margin-top: 1rem" [formGroup]="itemFormBilling" *ngIf="itemFormBilling"
  (ngSubmit)="submitBilling()" autocomplete="off">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 2rem">
    <div fxFlex="40" class="pl-16 full-width" style="margin-top: 0.5rem; margin-bottom: 1rem">
      <mat-slide-toggle [formControl]="itemFormBilling.controls['is_default']"></mat-slide-toggle>
      <label for="" class="pl-16 ml-5">
        {{
        itemFormBilling.value.is_default
        ? "Asignar por defecto"
        : "No asignar por defecto"
        }}
      </label>
    </div>

    <div fxFlex="40" class="pl-16" style="margin-top: 0.5rem; margin-bottom: 1rem">
      <mat-slide-toggle [formControl]="itemFormBilling?.controls['is_special_amount']">
      </mat-slide-toggle>
      <label for="" class="pl-16 ml-5">
        {{
        itemFormBilling.value.is_special_amount
        ? "Con monto particular para cuota"
        : "Sin monto particular para cuota"
        }}
      </label>
    </div>

    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input matInput type="number" min="0" (input)="nonNegative()" name="amount"
          [formControl]="itemFormBilling.controls['amount']" placeholder="Monto *" />
      </mat-form-field>
    </div>

    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input matInput type="number" min="0" (input)="nonNegative()" name="cycle"
          matTooltip="El ciclo de pago hace referencia a la cantidad de meses en que se generarán los cobros. Puede ser cada mes (1), cada dos meses (2), cada seis (6), etc..."
          [formControl]="itemFormBilling.controls['cycle']" placeholder="Ciclo de pago *" />
      </mat-form-field>
    </div>

    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input matInput type="number" min="0" (input)="nonNegative()" name="number_of_payments"
          matTooltip="Número total de pagos a realizar" [formControl]="itemFormBilling.controls['number_of_payments']"
          placeholder="Cantidad de pagos *" />
      </mat-form-field>
    </div>

    <div fxFlex="50" class="pr-16">
      <mat-form-field class="full-width">
        <input matInput name="external_id" [formControl]="itemFormBilling?.controls['external_id']" maxlength="4"
          placeholder="Código Externo" />
      </mat-form-field>
    </div>
    <div fxFlex="100" class="pr-16">
      <mat-form-field class="full-width">
        <mat-select placeholder="Cupón" name="coupon_id" [formControl]="itemFormBilling?.controls['coupon_id']"
          matNativeControl [readonly]="!ableUpdate">
          <mat-option [value]="null" (click)="selectCoupon(null)">Ningún cupón</mat-option>
          <mat-option [value]="coupon.id" *ngFor="let coupon of coupons" (click)="selectCoupon(coupon)">
            {{ coupon.title }} (Valor: {{ coupon.value }}
            {{ coupon.mode == "percentage" ? "%" : "S/" }} - Inicio:
            {{ coupon.start | date: "dd/MM/yyyy" }} - Fin:
            {{ coupon.until | date: "dd/MM/yyyy" }}
            {{ coupon.is_out_of_date ? "- VENCIDO" : "" }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="section-update-btn">
    <button mat-stroked-button type="submit" class="btn-primary" *ngIf="!editedBilling">
      Guardar
    </button>

    <button mat-stroked-button type="button" class="btn-update" [disabled]="itemFormBilling.invalid"
      *ngIf="editedBilling" (click)="updateBilling()">
      Actualizar
    </button>

    <button mat-stroked-button type="button" class="btn-danger" [disabled]="itemFormBilling.invalid"
      *ngIf="editedBilling" (click)="clearFormBilling()">
      Cancelar
    </button>
  </div>
</form>

<ngx-datatable class="material ml-0 mr-0" [rows]="listBillings" [columnMode]="'force'" [headerHeight]="50"
  footerHeight="auto" [scrollbarH]="true" [limit]="8" [rowHeight]="50" *ngIf="listBillings">
  <ngx-datatable-column name="Monto" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.amount }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Ciclo de pago" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.cycle }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Número de pagos" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.number_of_payments }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Estado" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.is_default ? "Por defecto" : "Opcional" }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Acciones" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button mat-icon-button color="accent" type="button" class="mr-8" (click)="editBilling(row)">
        <mat-icon>settings</mat-icon>
      </button>
      <button mat-icon-button color="warn" type="button" (click)="deleteBilling(row)">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>