import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Paginator } from "../models/paginator.model";

@Injectable({
    providedIn: "root",
})
export class PaginatorService {
    constructor() {}

    transformParams(paginator: Paginator, params: {}) {
        const pagination: any = {
            page: paginator.page.toString() || paginator.currentPage.toString(),
            per_page: paginator.perPage.toString(),
        };
        return new HttpParams({
            fromObject: { ...pagination, ...params },
        });
    }
}
