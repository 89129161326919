import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./header-top/header-top.component";
import { SidebarTopComponent } from "./sidebar-top/sidebar-top.component";

// ONLY FOR DEMO
import { CustomizerComponent } from "./customizer/customizer.component";

// ALWAYS REQUIRED
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";
import { ButtonLoadingComponent } from "./button-loading/button-loading.component";
import {
  EgretSidebarComponent,
  EgretSidebarTogglerDirective,
} from "./egret-sidebar/egret-sidebar.component";
import { BottomSheetShareComponent } from "./bottom-sheet-share/bottom-sheet-share.component";
import { EgretExampleViewerComponent } from "./example-viewer/example-viewer.component";
import { EgretExampleViewerTemplateComponent } from "./example-viewer-template/example-viewer-template.component";
import { EgretNotifications2Component } from "./egret-notifications2/egret-notifications2.component";
import { EditMessageModalComponent } from "../modals/edit-message-modal/edit-message-modal.component";
import { EditCertificateModalComponent } from "../modals/edit-certificate-modal/edit-certificate-modal.component";
import { EditDegreeModalComponent } from "../modals/edit-degree-modal/edit-degree-modal.component";
import { EditRecordModalComponent } from "../modals/edit-record-modal/edit-record-modal.component";
import { EditFeedbackModalComponent } from "../modals/edit-feedback-modal/edit-feedback-modal.component";
import { SelectGroupComponent } from "../modals/select-group/select-group.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SelectBillingComponent } from "../modals/select-billing/select-billing.component";
import { SelectEvaluationModalComponent } from "../modals/select-evaluation-modal/select-evaluation-modal.component";

import { SelectForumModalComponent } from "../modals/select-forum-modal/select-forum-modal.component";
import { SelectTopicModalComponent } from "../modals/select-topic-modal/select-topic-modal.component";
import { EditSuspendSubscriptionModalComponent } from "../modals/edit-suspend-subscription-modal/edit-suspend-subscription-modal.component";
import { CourseComponent } from "./course/course.component";
import { ForumItemComponent } from "./forum-item/forum-item.component";
import { HeaderInCourseComponent } from "./header-in-course/header-in-course.component";
import { QuillModule } from "ngx-quill";
import { EvaluationRatingModalComponent } from "../modals/evaluation-rating-modal.component/evaluation-rating-modal.component";
import { SelectQuizModalComponent } from "../modals/select-quiz-modal/select-quiz-modal.component";
import { ConversationsListComponent } from "./conversations-list/conversations-list.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ReportSubscriptionsComponent } from "./report-subscriptions/report-subscriptions.component";
import { ReportPaymentsComponent } from "./report-payments/report-payments.component";
import { ReportAttendancesComponent } from "./report-attendances/report-attendances.component";

const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  EgretNotifications2Component,
  CustomizerComponent,
  ButtonLoadingComponent,
  EgretSidebarComponent,
  FooterComponent,
  EgretSidebarTogglerDirective,
  BottomSheetShareComponent,
  EgretExampleViewerComponent,
  EgretExampleViewerTemplateComponent,
  EditMessageModalComponent,
  EditCertificateModalComponent,
  EditDegreeModalComponent,
  EditRecordModalComponent,
  EditFeedbackModalComponent,
  SelectGroupComponent,
  SelectQuizModalComponent,
  SelectBillingComponent,
  SelectEvaluationModalComponent,
  SelectForumModalComponent,
  SelectTopicModalComponent,
  EditSuspendSubscriptionModalComponent,
  CourseComponent,
  ForumItemComponent,
  HeaderInCourseComponent,
  EvaluationRatingModalComponent,
  ConversationsListComponent,
  ReportSubscriptionsComponent,
  ReportPaymentsComponent,
  ReportAttendancesComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    QuillModule,
    CKEditorModule,
  ],
  declarations: components,
  // entryComponents: [AppComfirmComponent, AppLoaderComponent, BottomSheetShareComponent],
  exports: components,
})
export class SharedComponentsModule {}
