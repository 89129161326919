import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Course } from '../../models/course.model';

@Component({
  selector: "app-course-card",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.scss"],
})
export class CourseComponent implements OnInit, OnDestroy {
  routeParts: any[];
  routerEventSub: Subscription;

  @Input() course: Course;

  constructor(private router: Router) {}

  ngOnInit() {}
  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  goCourse = (course: any): void => {
    this.router.navigate([`courses/${course.id}`]);
  };

  getImage(course: Course): string {
    if (course.main_image) {
      let original_url = course.main_image.original_url;
      if (
        original_url.indexOf("http://") !== 0 &&
        original_url.indexOf("https://") !== 0
      ) {
        original_url = `http://${original_url}`;
      }
      return original_url;
    }

    return `assets/images/tech.jpg`;
  }
}
