import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snackbar: MatSnackBar
  ) {}

  /**
   * Toast for info
   */

  public messageDeleteTopic:string = "También se eliminarán todas las sesiones que pertenecen al módulo";

  public typeSuccess: string = "Aceptar";
  public typeError: string = "Reintentar";

  public messageSuccess: string = "Acción realizada correctamente";
  public messageError: string = "Ha ocurrido un error, intente nuevamente";

  public messageValidateField: string =
    "Complete la información requerida";
  public messagePermission: string =
    "No cuenta con los permisos para realizar está acción";
  
  public messagePriceInvalided: string =
    "Ingrese el precio del curso";

  public messageCodeExternalValided: string =
    "Ingrese el código externo del curso para que pueda ser comprado desde la ecommerce";

  /**
   * Messages
   */
  public messageSendSuccessfully: string = "Mensaje enviado";
  public messageEditedSuccessfully: string = "Mensaje editado";
  
  /**
   * Messages
   */
  public subscriptionSuspendedSuccessfully: string = "Matrícula actualizada";

  /**
   * roles
   */
  public roleUpdatedSuccessfully: string = "Rol actualizado";

  public existCorrectAnswer: string = "Solo se permite una respuesta correcta";

  getJwtToken() {}

  showAlert(message: string, type: string) {
    this.snackbar.open(message, type, {
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  showErrors(error: any) {

    if (error.status == 403) {
      this.showAlert(error.error.message, this.typeError);
      return;
    }

    if (error.status !== 422) {
      return;
    }

    if (error.error && error.error.errors) {
      let message = "";
      Object.values(error.error.errors).forEach((element) => {  
        message += `${element[0]}` ;
      });

      this.showAlert(message, this.typeError);
      return;
    }

    if (error.error && !error.error.errors && error.error.message) {
      this.showAlert(error.error.message, this.typeError);
      return;
    }
  }
}
