import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { LocalStoreService } from "./local-store.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private alertService: AlertService
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  suspendSubscription(
    subscription_id: number,
    data: {
      suspended_reason: string;
      suspended_at: Date;
    }
  ) {
    return this.http
      .patch(
        `${environment.apiURL}/subscriptions/${subscription_id}/suspend`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  reactivateSubscription(subscription_id: number) {
    return this.http
      .patch(
        `${environment.apiURL}/subscriptions/${subscription_id}/reactivate`,
        [],
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  restoreSubscription(subscription_id: number) {
    return this.http
      .post(
        `${environment.apiURL}/subscriptions/${subscription_id}/restore`,
        [],
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  deleteSubscription(subscription_id: number) {
    return this.http
      .delete(
        `${environment.apiURL}/subscriptions/${subscription_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  updateAverageSubscription(subscription_id: number) {
    return this.http
      .put(
        `${environment.apiURL}/subscriptions/${subscription_id}/average`,
        [],
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
