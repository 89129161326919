import { Component, OnInit, Inject } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Paginator } from "app/shared/models/paginator.model";
import { Quiz } from "app/shared/models/quiz.model";

import { Subscription } from "app/shared/models/subscription.model";
import { CourseService } from "app/shared/services/course.service";
import { GroupService } from "app/shared/services/group.service";
import { QuizService } from "app/shared/services/quiz.service";
import { CustomMatPaginatorIntl } from "app/views/courses/utils/paginator-es";


@Component({
  selector: 'app-select-quiz-modal',
  templateUrl: './select-quiz-modal.component.html',
  styleUrls: ['./select-quiz-modal.component.scss']
})
export class SelectQuizModalComponent implements OnInit {
  public paginator: Paginator = Paginator.getDefault();
  private queryParams: {} = {};
  public filteredOptions: any[] = [];
  public per_page: number = 10;
  public page: number = 1;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent;
  public date : any ;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectQuizModalComponent>,
    public quizService: QuizService,
  ) { 
    this.data = data
    this.getQuizzes()
  }

  getQuizzes(){
    let params = {
      ...this.queryParams,
    };

    this.quizService.getQuiz(this.data.course_id,this.paginator, params).subscribe((res: any) => {
        res.data.forEach(element => {
            if(element.id == this.data.id){
              element.selected = true;
            } 
        });
       this.filteredOptions = res.data;
       this.paginator = new Paginator(res.meta);
    })
  }

  get length() {
    return this.paginator.total;
  }
  ngOnInit(): void {
    console.log(this.data)
  }

  handlePage(e: PageEvent) {
    this.per_page = e.pageSize;
    this.page = e.pageIndex + 1;

    this.paginator.perPage = this.per_page;
    this.paginator.page = this.page;
    
    this.getQuizzes()
    
  }

  seleccionar(row) {
    this.dialogRef.close(row);
  }

}