import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MY_FORMATS } from "app/shared/components/tab-billing/tab-billing.component";
import { Subscription } from "app/shared/models/subscription.model";
import { SubscriptionService } from "app/shared/services/subscription.service";
import { AlertService } from "../../services/alert.service";

@Component({
  selector: "edit-suspend-subscription-modal",
  templateUrl: "edit-suspend-subscription-modal.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-PE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditSuspendSubscriptionModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Subscription,
    public dialogRef: MatDialogRef<EditSuspendSubscriptionModalComponent>,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService
  ) {}
  ngOnInit(): void {
    let now = new Date();

    this.form = new FormGroup({
      suspended_reason: new FormControl(this.data ? this.data.user.name : "", [
        Validators.required,
        Validators.maxLength(255),
      ]),
      suspended_at: new FormControl(
        this.data && this.data.suspended_at ? this.data.suspended_at : now,
        [Validators.required]
      ),
    });
  }

  get suspended_reason() {
    return this.form.get("suspended_reason");
  }

  get suspended_at() {
    return this.form.get("suspended_at");
  }

  onSubmit() {
    if (this.form.invalid) {
      this.suspended_reason.markAsDirty();
      this.suspended_reason.markAsTouched();
      this.suspended_at.markAsDirty();
      this.suspended_at.markAsTouched();
      return;
    }

    this.subscriptionService
      .suspendSubscription(this.data.id, {
        suspended_reason: this.suspended_reason.value,
        suspended_at: this.suspended_at.value,
      })
      .subscribe(() => {
        this.alertService.showAlert(
          this.alertService.subscriptionSuspendedSuccessfully,
          this.alertService.typeSuccess
        );
        this.form.reset();
        this.dialogRef.close(true);
      });
  }
}
