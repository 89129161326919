import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any ,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) { }

  ngOnInit(): void {
    if(this.data != null){
      this.message = this.data.title ? this.data.title : this.data 
    } else{
      this.message  = "¿Está seguro(a) de realizar dichos cambios?"
    }
  }

  handleSelectBilling(row){
    this.dialogRef.close(row);
  }

}
