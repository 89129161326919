<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
  <h3 mat-dialog-title>Datos de la puntuación</h3>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap" fxLayout.lt-sm="column">
      <div fxFlex="100" class="pl-16" style="margin-top: 0.5rem; margin-bottom: 1rem">
        <mat-form-field class="full-width">
          <input matInput readonly="true" type="text" name="user" [value]="data?.user.name"
            placeholder="Nombre de usuario" />
        </mat-form-field>
      </div>

      <div fxFlex="100" class="pl-16" style="margin-top: 0.5rem; margin-bottom: 1rem">
        <mat-form-field class="full-width">
          <input matInput readonly="true" type="text" name="course" [value]="data?.course.title" placeholder="Curso" />
        </mat-form-field>
      </div>

      <div fxFlex="60" class="pl-16" style="margin-top: 0.5rem; margin-bottom: 1rem">
        <mat-form-field class="full-width">
          <input matInput type="number" name="rating" [formControl]="rating" placeholder="Puntuación *" min="1"
            max="5" />
          <mat-error *ngIf="rating.hasError('required')">
            La puntuación es requerida
          </mat-error>
          <mat-error *ngIf="rating.hasError('min')">
            La puntuación debe ser un mínimo de 1
          </mat-error>
          <mat-error *ngIf="rating.hasError('max')">
            La puntuación debe ser un máximo de 5
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="40" class="pl-16" style="margin-top: 0.5rem; margin-bottom: 1rem">
        <mat-slide-toggle [formControl]="in_store"></mat-slide-toggle>
        <label for="" class="pl-16 ml-5">
          {{ in_store ? "Mostrar en ecommerce" : "No mostrar en ecommerce"
          }}
        </label>
      </div>


      <mat-form-field class="w-100">
        <mat-label>Comentario</mat-label>
        <textarea placeholder="Agregue un comentario..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3" class="w-100" formControlName="comment"></textarea>
        <mat-error *ngIf="comment.hasError('required')">
          Complete su comentario
        </mat-error>
        <mat-error *ngIf="comment.hasError('maxLength')">
          El contenido no puede tener más de 5,000 caracteres
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mr-auto">Cerrar</button>
    <button mat-stroked-button color="success" type="submit">Enviar</button>
  </mat-dialog-actions>
</form>