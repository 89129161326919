import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { Conversation } from "../models/conversation.model";
import { Message } from "../models/messages.model";
import { LocalStoreService } from "./local-store.service";
import { AlertService } from './alert.service';

@Injectable({
  providedIn: "root",
})
export class MessageService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private snack: MatSnackBar,
    private alertService: AlertService
  ) {}

  getUuids(): Observable<{ data: { uuids: string } }> {
    return this.http
      .get<any>(`${environment.apiURL}/messages-uuids`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getLastsMessages(uuids: string): Observable<{ data: Message[] }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/messages-lasts?uuids=${uuids}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getHistory(uuid: string): Observable<{ data: any }> {
    return this.http
      .get<any>(`${environment.apiURL}/messages/${uuid}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  storeMessage(data: {
    course_id: number | null;
    content: string;
    user_ids: number[];
    uuid: string | null;
    published_at: string | null;
    to_support: boolean;
  }): Observable<{ data: any }> {
    if (!data.course_id) {
      delete data.course_id;
    }

    if (!data.uuid) {
      delete data.uuid;
    }

    return this.http
      .post<any>(`${environment.apiURL}/messages`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  updateMessage(
    message_id: number,
    data: {
      content: string;
      published_at: string | null;
      to_support: boolean;
    }
  ): Observable<{ data: any }> {
    return this.patchMessage(message_id, data);
  }

  updateMessageStatus(
    message_id: number,
    data: {
      status: string;
    }
  ): Observable<{ data: any }> {
    return this.patchMessage(message_id, data);
  }

  patchMessage( message_id: number, data: any): Observable<{ data: any }> {
    return this.http
      .put<any>(
        `${environment.apiURL}/messages/${message_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  storeForumReply(
    parent_id: number,
    data: {
      subject: string;
      content: string;
    }
  ): Observable<{ data: any }> {
    return this.http
      .post<any>(
        `${environment.apiURL}/topics-forums/${parent_id}/reply`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getConversations(
    data: {
      per_page: number;
      page: number;
    }
  ): Observable<{ data: Conversation[]; meta: any }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/conversations?is_support=true&per_page=${data.per_page}&page=${data.page}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
