import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Course } from "app/shared/models/course.model";
import { Group } from "app/shared/models/group.model";
import { Paginator } from "app/shared/models/paginator.model";
import { AlertService } from "app/shared/services/alert.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { CourseService } from "app/shared/services/course.service";
import { GroupService } from "app/shared/services/group.service";
import { UsersService } from "app/shared/services/users.service";
import { Subscription } from "app/shared/models/subscription.model";
import { LearningPackage } from "app/shared/models/learning_package.model";

@Component({
  selector: "app-report-subscriptions",
  templateUrl: "./report-subscriptions.component.html",
})
export class ReportSubscriptionsComponent implements OnInit {
  @Input() courses: Course[] = [];
  @Input() learning_packages: LearningPackage[] = [];
  public paginator: Paginator = Paginator.getDefault();

  private queryParams: {} = {};
  public groups: Group[] = [];
  public per_page: number = 10;
  public page: number = 1;
  public pageSizeOptions = [5, 10, 25, 50];
  pageEvent: PageEvent;
  public subscriptions: Subscription[] = [];
  public course_id: number = null;
  public group_id: number = null;
  public learning_package_id: number = null;
  public courseInput = null;

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private router: Router,
    private loader: AppLoaderService,
    private alertService: AlertService,
    private jwAuth: JwtAuthService,
    private courseService: CourseService,
    private groupService: GroupService,
    private snack: MatSnackBar,
    private fb: FormBuilder,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    
  }

  getGroups(search?: string): void {
    let params = {};
    params = {
      title: search,
      without_pagination: true,
    };
    params = {
      ...params,
      ...this.queryParams,
    };

    this.groups = [];
    if (!this.course_id) {
      return;
    }
    this.groupService
      .getGroupsPaginator(this.course_id, this.paginator, params)
      .subscribe((response: any) => {
        this.groups = response.data;

        let group: Group = {
          id: 0,
          name: "Todos",
          is_default: false,
        };

        this.groups.unshift(group);
      });
  }

  changeCourse(course_id: number) {
    if (course_id != 0) {
      this.course_id = course_id;
      this.getGroups("");
    }
  }

  changeLearningPackage(learning_package_id: number) {
    if (learning_package_id != 0) {
      this.learning_package_id = learning_package_id;
    }
  }

  getSubscriptions() {
    let params = {
      course_id: this.course_id,
      learning_package_id: this.learning_package_id,
      group_id: this.group_id,
      includes: "learning_package",
    };
    if (this.learning_package_id === 0 || !this.learning_package_id) {
      delete params.learning_package_id;
    }
    this.usersService
      .getSubscriptionsPagination(this.paginator, params)
      .subscribe((response: any) => {
        this.loader.close();
        this.subscriptions = response.data;
        this.paginator = new Paginator(response.meta);
      });
  }

  downloadSubscriptions() {
    let data = {
      course_id: this.course_id,
      group_id: this.group_id,
    };

    if (!data.course_id) {
      delete data.course_id;
    }
    if (!data.group_id) {
      delete data.group_id;
    }

    this.usersService.reportSubscriptions(data).subscribe((res: any) => {
      this.downloadFile(res);
      this.alertService.showAlert(
        this.alertService.messageSuccess,
        this.alertService.typeSuccess
      );
    });
  }

  downloadUsers() {
    let data = [];
    this.usersService.reportUsers(data).subscribe((res: any) => {
      this.downloadFile(res);
      this.alertService.showAlert(
        this.alertService.messageSuccess,
        this.alertService.typeSuccess
      );
    });
  }

  getStatus(subscription: Subscription) {
    if (subscription.deleted_at) {
      return `Eliminada`;
    } else if (subscription.suspended_at) {
      return `Suspendida`;
    } else {
      return `Activa`;
    }
  }

  clearSubscriptionFilters() {
    this.course_id = null;
    this.courseInput = null;
    this.getSubscriptions();
  }

  get length() {
    return this.paginator.total;
  }

  handlePage(e: PageEvent) {
    this.per_page = e.pageSize;
    this.page = e.pageIndex + 1;

    this.paginator.perPage = this.per_page;
    this.paginator.page = this.page;

    this.getSubscriptions();
  }

  toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
  }

  downloadFile(data: Blob) {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/vnd.ms-excel" })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "Reporte_Matriculas.xls");
    document.body.appendChild(link);
    link.click();
  }

  get ableDownload() {
    return this.userService.able("download_reports");
  }

  getTitle(learning_package: LearningPackage) {
    let title = learning_package.title;
    if (learning_package.template) {
      return `${learning_package.template.title}_${title}`;
    }
    return title;
  }
}
