import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileTypes",
})
export class FileTypesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case "certificate":
        value = "Certificado";
        break;
      case "degree":
        value = "Diploma";
        break;
      case "record":
        value = "Constancia";
        break;
    }

    return value;
  }
}
