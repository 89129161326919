import { Pipe, PipeTransform } from "@angular/core";

const status = [
  {
    key: "pending",
    value: "Pendiente de pago",
  },
  {
    key: "paid",
    value: "Pagado",
  },
  {
    key: "canceled",
    value: "Cancelado",
  },
  {
    key: "confirmed",
    value: "Pago confirmado",
  },
  {
    key: "tardiness",
    value: "Tardanza",
  },
  {
    key: "on_time",
    value: "Presente",
  },
  {
    key: "missing",
    value: "Ausente",
  },
];
@Pipe({
  name: "status",
})
export class StatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let index = status.findIndex((status) => {
      return status.key === value;
    });

    if (index > -1) {
      return status[index].value;
    }

    return "";
  }
}
