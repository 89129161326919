    <h4>Grupos</h4>

    <mat-card class="p-0">
        <mat-card-content class="p-0">
            <ngx-datatable class="material ml-0 mr-0" [rows]="filteredOptions" columnMode="force" [headerHeight]="50"
                footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

                <ngx-datatable-column name="Nombre" [width]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row?.name }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Fechas">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        Inicio: {{ row?.date_start | date: "dd/MM/yy" }}
                        <br>
                        {{ (row?.date_until)? 'Fin:': '' }} {{ row?.date_until | date: "dd/MM/yy" }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Vacantes">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{( row?.is_unlimited ) ? 'Ilimitadas' : row?.vacancies }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Acción">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <button *ngIf="row?.selected" class="button-7 bg-red noselect" type="button"
                            style="margin-right: 0.5rem;" disabled>Seleccionado</button>

                        <button *ngIf="!row?.selected" class="button-7 bg-blue" type="button"
                            style="margin-right: 0.5rem;" (click)="seleccionar(row)">Seleccionar</button>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>

                        <div class="full-width paginator-container">
                            <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
                                aria-label="Select page" (page)="handlePage($event)">
                            </mat-paginator>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>



        </mat-card-content>
    </mat-card>