<mat-card style="padding: 2rem;">
    <h3>Reporte - Pagos</h3>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 2rem">

        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Ruta/Paquete" name="learning_package_id" [(ngModel)]="learning_package_id"
                    matNativeControl (valueChange)="changeLearningPackage($event)">
                    <mat-option [value]="0">
                        Todos
                    </mat-option>
                    <mat-option [value]="learning_package?.id" *ngFor="let learning_package of learning_packages">
                        {{ getTitle(learning_package) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Curso" name="course_id" [(ngModel)]="course_id" matNativeControl
                (valueChange)="changeCourse($event)">
                    <mat-option [value]="null">
                        Todos
                    </mat-option>
                    <mat-option [value]="course?.id" *ngFor="let course of courses">
                        {{course?.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="section-btn mt-16">
        <button mat-stroked-button type="button" class="btn-warning" (click)="downloadPayments()" *ngIf="ableDownload">
            Descarga excel
        </button>
        <div>
            <button mat-stroked-button type="button" class="btn-danger mr-4" (click)="clearPaymentFilters()">
                Limpiar
            </button>
            <button mat-stroked-button type="button" class="btn-primary" (click)="getPayments()">
                Mostrar
            </button>
        </div>
    </div>

    <mat-card class="p-0 tabresults">
        <mat-card-content class="p-0">
        <ngx-datatable class="material ml-0 mr-0" [rows]="payments" [columnMode]="'force'" [headerHeight]="50"
            footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

            <ngx-datatable-column name="Nombre" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.user.name }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Curso" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ getCourse(row) }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Concepto" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.items[0].title }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Tipo" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.items[0].model_type | modelType }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="F. Registro" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.created_at | date:"dd/MM/YYYY HH:mm"}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Estado" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.status | status }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="F. Confirmación" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.confirmed_at  | date:"dd/MM/YYYY HH:mm" }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
                <div class="full-width paginator-container">
                <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
                    aria-label="Select page" (page)="handlePage($event)">
                </mat-paginator>
                </div>
            </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
        </mat-card-content>
    </mat-card>
</mat-card>