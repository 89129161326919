import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";
import { Conversation } from "../models/conversation.model";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsMedia = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.getJwtToken()}`,
      "Content-Type": "multipart/form-data",
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}

  getCoursesByName(filter) {
    return this.http
      .get(`${environment.apiURL}/courses`, this.httpOptions)
      .pipe(
        map((res: any) => {
          let array = [];
          res.data.forEach((element) => {
            if (element.title.toLowerCase().includes(filter.toLowerCase())) {
              array.push(element);
            }
          });
          return array;
        }),
        //  tap( _ => this.loader.close()),

        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCourses(cantElementos = 0, inicio = 0) {
    return this.http
      .get(`${environment.apiURL}/courses`, this.httpOptions)
      .pipe(
        tap((_) => this.loader.close()),
        // tap( (res:any) => console.log(res)),
        map((res: any) =>
          cantElementos == 0 ? res.data : res.data.slice(inicio, cantElementos)
        ),

        catchError((error) => {
          // console.log(error)
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCoursesPaginator(
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    return this.http.get(`${environment.apiURL}/courses`, params).pipe(
      tap((_) => this.loader.close()),
      tap((res: any) => {}),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public getCourses2(idUser): Observable<any> {
    return this.http
      .get(`${environment.apiURL}/courses?user_id=${idUser}`, this.httpOptions)
      .pipe(
        tap((_) => this.loader.close()),
        // tap( (res:any) => console.log(res)),
        // map((res: any) => res.data),

        catchError((error) => {
          // console.log(error)

          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getMyCourses(cantElementos = 0, inicio = 0) {
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/courses`, this.httpOptions)
      .pipe(
        map((res: any) =>
          cantElementos == 0 ? res.data : res.data.slice(inicio, cantElementos)
        ),
        tap((_) => this.loader.close()),

        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCourse(id) {
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/courses/${id}`, this.httpOptions)
      .pipe(
        // // tap(res => console.log(res)),

        map((res: any) => res.data),
        tap((_) => this.loader.close()),

        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCourseSimple(id: number) {
    return this.http
      .get(`${environment.apiURL}/courses-simple/${id}`, this.httpOptions)
      .pipe(
        map((res: any) => res.data),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateCourse(id, data) {
    return this.http
      .put(`${environment.apiURL}/courses/${id}`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  updateLinks(course_id: number, data) {
    return this.http
      .put<any>(
        `${environment.apiURL}/courses/${course_id}/links`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateImage(id, type, body) {
    this.loader.open();

    return this.http
      .put(
        `${environment.apiURL}/courses/${id}/${type}`,
        body,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteCourse(id) {
    return this.http
      .delete(`${environment.apiURL}/courses/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeCourse(data): Observable<{ data: any }> {
    return this.http
      .post<any>(`${environment.apiURL}/courses`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCategories() {
    return this.http
      .get(`${environment.apiURL}/categories?type=course`, this.httpOptions)
      .pipe(
        // // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getCategoriesEventos() {
    return this.http
      .get(`${environment.apiURL}/categories?type=event`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getUsers(role, filter) {
    return this.http
      .get(
        `${environment.apiURL}/users?role=${role}&name=${filter}`,
        this.httpOptions
      )
      .pipe(
        tap((a: any) => console.log(a)),
        map((a: any) => a.data),
        map((a: any) => {
          let results = [];
          a.forEach((element) => {
            let nameComplete = element.first_name + element.last_name;
            if (
              element.role == role &&
              nameComplete.toLowerCase().includes(filter.toLowerCase())
            ) {
              results.push(element);
            }
          });
          return results;
        }),

        tap((a: any) => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getDocentesSearch(filterValue: string) {
    return this.http
      .get(`${environment.apiURL}/groups-users`, this.httpOptions)
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (
              element.type == "teacher" &&
              element.user.first_name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            ) {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')

          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getStudentsSearch(filterValue: string) {
    return this.http
      .get(`${environment.apiURL}/groups-users`, this.httpOptions)
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (
              element.type == "student" &&
              element.user.first_name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            ) {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getDocentes(group_id) {
    return this.http
      .get(
        `${environment.apiURL}/groups-users?type=teacher&group_id=${group_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          let types = [
            "teacher",
            "tutor",
            "instructor",
            "facilitator",
            "academic_tutor",
            "umbrella_tutor",
            "administrative_tutor",
          ];
          a.forEach((element) => {
            if (
              types.includes(element.type) ||
              types.includes(element.subtype)
            ) {
              onlydocentes.push(element);
            }
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getStudents(group_id) {
    return this.http
      .get(
        `${environment.apiURL}/groups-users?group_id=${group_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (element.type == "student") {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getStudentsPaginator(
    group_id,
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    //console.log(params)
    params = { ...this.httpOptions, params };
    return this.http
      .get(`${environment.apiURL}/groups-users?group_id=${group_id}`, params)
      .pipe(
        // map((a: any) => a.data),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeDocente(body) {
    return this.http
      .post(`${environment.apiURL}/groups-users`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeEstudiante(body) {
    return this.http
      .post(`${environment.apiURL}/groups-users`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteDocente(id) {
    return this.http
      .delete(`${environment.apiURL}/groups-users/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteEstudiante(id) {
    return this.http
      .delete(`${environment.apiURL}/groups-users/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getTopics(course_id) {
    // console.log(course_id)
    return this.http
      .get(
        `${environment.apiURL}/topics?course_id=${course_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),

        map((res) => {
          // //console.log(res)
          let appItems = [];
          res.forEach((element) => {
            //let element = data
            if (element.parent_id == null) {
              element.label = element.order + ". " + element.title;
              element.icon = "offline_pin";
              element.enlace = element.link;
              element.order = element.order;
              // //console.log(element)
              element.link = "";
              element.test = element.id;
              //element.routerLink = '../module-session/'+'module/'+element.id
              appItems.push(element);
              element.items = [];
              // console.log(element)
              element.items.push({
                title: element.title,
                course_id: element.course_id,
                label: "Editar módulo",
                test: element.id,
              });

              res.forEach((element2) => {
                if (element2.parent_id == element.id) {
                  element2.label = element2.order + ". " + element2.title;
                  element2.icon = "list";
                  element2.enlace = element2.link;
                  element2.link = "";
                  element2.test = element2.id;
                  element2.order = element2.order;
                  element.items.push(element2);
                }
              });

              element.items.push({
                label: "Agregar sesión",
                link:
                  "/courses/" +
                  element.course_id +
                  "/topics/" +
                  "session/" +
                  element.id,
              });
              element.label =
                element.label +
                " (" +
                (element.items.length - 2).toString() +
                " sesiones)";
            }
          });
          return appItems;
        }),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  postTopics(data) {
    return this.http
      .post(`${environment.apiURL}/topics`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  putTopics(id, body) {
    if (!body.time_session) {
      delete body.time_session;
    }

    if (!body.percentage_to_complete) {
      delete body.percentage_to_complete;
    }
    return this.http
      .put(`${environment.apiURL}/topics/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  deleteTopic(id) {
    return this.http
      .delete(`${environment.apiURL}/topics/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeAvatar(avatar) {
    return this.http
      .post(
        `${environment.apiURL}/users/avatar`,
        { avatar: avatar },
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  putTLimitedOfCourse(id, body) {
    return this.http
      .put(`${environment.apiURL}/limited-course/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  changeActiveStateCourse(id, body) {
    return this.http
      .put(
        `${environment.apiURL}/change-active-state/${id}`,
        body,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  //* TODO: /api/courses/images/{image}
  deleteImage(id: any) {
    return this.http
      .delete(`${environment.apiURL}/courses/images/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public reactivateCourse(id) {
    this.loader.open();
    return this.http
      .patch(
        `${environment.apiURL}/courses/${id}/reactivate`,
        [],
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public suspendCourse(id) {
    this.loader.open();
    return this.http
      .patch(
        `${environment.apiURL}/courses/${id}/suspend`,
        [],
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getForum(params: {}) {
    params = { ...this.httpOptions, params };
    return this.http.get(`${environment.apiURL}/topics-forums`, params).pipe(
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public getForumPagination(
    courseId,
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    return this.http
      .get<any>(
        `${environment.apiURL}/topics-forums?course_id=${courseId}`,
        params
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeForum(data) {
    return this.http
      .post(`${environment.apiURL}/topics-forums`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  updateForum(id, body) {
    return this.http
      .put(`${environment.apiURL}/topics-forums/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteForum(id) {
    return this.http
      .delete(`${environment.apiURL}/topics-forums/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getHistoryForum(uuid: string): Observable<{ data: any[] }> {
    let url = `${environment.apiURL}/topics-forums/${uuid}/history`;

    return this.http.get<any>(url, this.httpOptions);
  }

  nextOrderTopic(
    course_id: number,
    parent_id: number | null
  ): Observable<{ data: number }> {
    let params: string = `course_id=${course_id}&parent_id=${parent_id}`;
    if (!parent_id) {
      params = `course_id=${course_id}`;
    }
    return this.http
      .get<any>(
        `${environment.apiURL}/topics-next-order?${params}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeTopicFile(topic_id: number, data: any) {
    this.loader.open();

    return this.http
      .put(
        `${environment.apiURL}/topics/${topic_id}/files`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getTopicFiles(topic_id: number) {
    return this.http.get<any>(
      `${environment.apiURL}/topics/${topic_id}/files`,
      this.httpOptions
    );
  }

  getConversations(
    course_id: number | null,
    to_support: boolean,
    data: {
      per_page: number;
      page: number;
    }
  ): Observable<{ data: Conversation[]; meta: any }> {
    let urlParams = `per_page=${data.per_page}&page=${data.page}&includes=first_message`;
    if (to_support) {
      urlParams += `&to_support=true`;
    } else {
      urlParams += `&course_id=${course_id}`;
    }
    return this.http
      .get<any>(
        `${environment.apiURL}/conversations?${urlParams}`,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
