import { Component, OnInit, Inject } from "@angular/core";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { DialogConfirmComponent } from "app/shared/components/dialog-confirm/dialog-confirm.component";
import { Evaluation } from "app/shared/models/evaluation.model";
import { Forum } from "app/shared/models/forum.model";
import {
  Paginator,
  PaginatorResponse,
} from "app/shared/models/paginator.model";
import { AlertService } from "app/shared/services/alert.service";
import { EvaluationService } from "app/shared/services/evaluation.service";
import { CustomMatPaginatorIntl } from "app/views/courses/utils/paginator-es";

@Component({
  selector: "app-select-evaluation-modal",
  templateUrl: "./select-evaluation-modal.component.html",
  styleUrls: ["./select-evaluation-modal.component.scss"],
  providers: [
    { provide: MatPaginatorIntl, useValue: new CustomMatPaginatorIntl() },
  ],
})
export class SelectEvaluationModalComponent implements OnInit {
  public paginator: Paginator = Paginator.getDefault();
  private queryParams: {} = {};
  public evaluations: any[] = [];
  public per_page: number = 10;
  public page: number = 1;
  public pageSizeOptions = [5, 10, 25, 50];
  pageEvent: PageEvent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SelectEvaluationModalComponent>,
    public evaluationService: EvaluationService,
    public dialog: MatDialog,
    public alertService: AlertService
  ) {
    let params = {
      ...this.queryParams,
      course_id: this.data.course_id,
      group_id: this.data.group_id,
    };

    evaluationService
      .getEvaluations(this.paginator, params)
      .subscribe(
        (response: { data: Evaluation[]; meta: PaginatorResponse }) => {
          this.evaluations = response.data;
          this.evaluations.forEach((element) => {
            if (
              element.model_type == "forums" &&
              this.data.forum_id == element.model_id
            ) {
              element.selected = true;
            }
          });
          console.log(this.evaluations);
          this.paginator = new Paginator(response.meta);
        }
      );
  }

  get length() {
    return this.paginator.total;
  }
  ngOnInit(): void {
    console.log(this.data.forum_id);
  }

  seleccionar(row) {
    let data = {
      forum_id: this.data.forum_id,
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.evaluationService
          .assignmentEvaluation(row.id, data)
          .subscribe((res) => {
            this.alertService.showAlert(
              this.alertService.messageSuccess,
              this.alertService.typeSuccess
            );
          });
      }
      this.dialogRef.close(row);
    });
  }
}
