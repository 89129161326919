import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";
import { Topic } from "../models/topic.model";

@Injectable({
  providedIn: "root",
})
export class TopicService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}


  showTopic(topic_id: number): Observable<{ data: Topic }> {
    return this.http
      .get<any>(`${environment.apiURL}/topics/${topic_id}`, this.httpOptions)
      .pipe(
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  //topics-forums/{topic}
  
  public getForumByTopic(topic_id:number){
    return this.http
    .get<any>(`${environment.apiURL}/topics-forums/topics/${topic_id}`, this.httpOptions)
    .pipe(
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }
  

  public getTopics(course_id) {
    // console.log(course_id)
    return this.http
      .get(
        `${environment.apiURL}/topics?course_id=${course_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),

        map((res) => {
          // //console.log(res)
          let appItems = [];
          res.forEach((element) => {
            //let element = data
            if (element.parent_id == null) {
              element.label = element.order + ". " + element.title;
              element.icon = "offline_pin";
              element.enlace = element.link;
              element.order = element.order;
              // //console.log(element)
              element.link = "";
              element.test = element.id;
              //element.routerLink = '../module-session/'+'module/'+element.id
              appItems.push(element);
              element.items = [];
              // console.log(element)
              element.items.push({
                title: element.title,
                course_id: element.course_id,
                label: "Editar módulo",
                test: element.id,
              });

              res.forEach((element2) => {
                if (element2.parent_id == element.id) {
                  element2.label = element2.order + ". " + element2.title;
                  element2.icon = "list";
                  element2.enlace = element2.link;
                  element2.link = "";
                  element2.test = element2.id;
                  element2.order = element2.order;
                  element.items.push(element2);
                }
              });

              element.items.push({
                label: "Agregar sesión",
                link:
                  "/courses/" +
                  element.course_id +
                  "/topics/" +
                  "session/" +
                  element.id,
              });
              element.label =
                element.label +
                " (" +
                (element.items.length - 2).toString() +
                " sesiones)";
            }
          });
          return appItems;
        }),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  postTopics(data) {
    return this.http
      .post(`${environment.apiURL}/topics`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  putTopics(id, body) {
    return this.http
      .put(`${environment.apiURL}/topics/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  deleteTopic(id) {
    return this.http
      .delete(`${environment.apiURL}/topics/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }



  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  
  nextOrderTopic(
    course_id: number,
    parent_id: number | null
  ): Observable<{ data: number }> {
    let params: string = `course_id=${course_id}&parent_id=${parent_id}`;
    if (!parent_id) {
      params = `course_id=${course_id}`;
    }
    return this.http
      .get<any>(
        `${environment.apiURL}/topics-next-order?${params}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
