import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EditMessageModalComponent } from "app/shared/modals/edit-message-modal/edit-message-modal.component";
import { Conversation } from "app/shared/models/conversation.model";
import { CourseService } from "app/shared/services/course.service";

@Component({
  selector: "app-conversations-list",
  templateUrl: "./conversations-list.component.html",
})
export class ConversationsListComponent implements OnInit {
  public conversations: Conversation[] = [];
  public per_page: number = 20;
  public page: number = 1;
  public pageSizeOptions = [20, 50, 100];
  @Input() course_id: number = null;
  @Input() toSupport: boolean = false;

  constructor(
    private courseService: CourseService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.getConversations();
  }

  getConversations() {
    this.courseService
      .getConversations(this.course_id, this.toSupport, {
        per_page: this.per_page,
        page: this.page,
      })
      .subscribe((response) => {
        this.conversations = response.data;
      });
  }

  goMessage(conversation: Conversation) {
    this.router.navigate([`/messages/${conversation.uuid}`], {
      queryParams: { course_id: this.course_id },
    });
  }

  openDialog() {
    let config = new MatDialogConfig();
    config = {
      height: "auto",
      width: "100vw",
      panelClass: "full-screen-modal",
      data: {
        course_id: this.course_id,
        isEdit: false,
        to_support: this.toSupport
      },
    };

    const dialogRef = this.dialog.open(EditMessageModalComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("refresh");
        this.getConversations();
      }
    });
  }
}
