import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { catchError } from "rxjs/operators";
import { AppLoaderService } from "../services/app-loader/app-loader.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private jwtAuth: JwtAuthService,     private loader: AppLoaderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {  
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // remove Bearer token and redirect to login page
          this.loader.close();
          localStorage.clear();
          this.jwtAuth.signout();
        }
        return throwError(err);
      })
    );
  }
}
