import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Course } from "app/shared/models/course.model";
import { Group } from "app/shared/models/group.model";
import { Paginator } from "app/shared/models/paginator.model";
import { AlertService } from "app/shared/services/alert.service";
import { GroupService } from "app/shared/services/group.service";
import { UsersService } from "app/shared/services/users.service";
import { Payment } from 'app/shared/models/payment.model';
import { GroupUserService } from '../../services/group-user.service';

@Component({
    selector: "app-report-attendances",
    templateUrl: "./report-attendances.component.html",
  })
  export class ReportAttendancesComponent implements OnInit {
    @Input() courses: Course[] = [];
    public paginator: Paginator = Paginator.getDefault();

    private queryParams: {} = {};
    public groups: Group[] = [];
    public attendanceItems: any[] = [];
    public course_id: number = null;
    public group_id: number = null;
    public courseInput = null;

    constructor(
        public dialog: MatDialog,
        private alertService: AlertService,
        private groupService: GroupService,
        private userService: UsersService,
        private groupUserService: GroupUserService
    ) {}
    
    ngOnInit(): void {
    }

    getGroups(search?: string): void {
      let params = {};
      params = {
        title: search,
        without_pagination: true,
      };
      params = {
        ...params,
        ...this.queryParams,
      };

      this.groups = [];
      if (!this.course_id) {
        return;
      }
      this.groupService
        .getGroupsPaginator(this.course_id, this.paginator, params)
        .subscribe((response: any) => {
          this.groups = response.data;

          let group: Group = {
            id: 0,
            name: "Todos",
            is_default: false
          };

          this.groups.unshift(group);
        });
    }

    changeCourse(course_id: number) {
      this.course_id = course_id;
      this.group_id = null;
      this.getGroups("");
    }

    getAttendanceItems() {
      this.attendanceItems = [];
      this.groupUserService.getAttendanceItems({
        course_id: this.course_id,
        group_id: this.group_id,
      }).subscribe((response: any) => {
        this.attendanceItems = response.data;
      })
    }

    downloadPayments() {
      let data = {
        course_id: this.course_id,
        group_id: this.group_id,
        includes: "subscription"
      };

      if (!this.group_id) {
        delete data.group_id;
      }

      this.groupUserService.reportAttendancesDownload(data).subscribe((res: any) => {
        this.downloadFile(res);
        this.alertService.showAlert(
          this.alertService.messageSuccess,
          this.alertService.typeSuccess
        );
      });
    }

    clearPaymentFilters() {
      this.course_id = null;
      this.courseInput = null;
      this.getAttendanceItems();
    }

    getCourse(payment: Payment) {
      if (payment.items.length === 0) {
        return;
      }

      let title = "";
      payment.items.forEach((item) => {
        if (item.model_type === 'courses') {
          title = item.course.title;
        } else if (item.subscription) {
          title = item.subscription.course.title;
        }
      })

      return title;
    }

    downloadFile(data: Blob) {
      const url = window.URL.createObjectURL(new Blob([data], {type:'application/vnd.ms-excel'}));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Reporte_Asistencias.xls');
      document.body.appendChild(link);
      link.click();
    }

    get ableDownload() {
      return this.userService.able("download_reports");
    }
  }