import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay, tap, finalize } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";
import { environment } from "environments/environment";
import { AppLoaderService } from "../app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "../alert.service";
import { Permission, Role } from "app/shared/models/permission.model";

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  link: string = "";
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "APP_USER";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private loader: AppLoaderService,
    private alertService: AlertService,
    private snack: MatSnackBar
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/")
    );
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http
      .post(`${environment.apiURL}/login`, { email: username, password })
      .pipe(
        map((res: any) => {
          this.signingIn = false;
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getProfile(token = null) {
    let headers;
    if (!token) {
      token = this.getJwtToken();
    }

    headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    this.signingIn = true;
    return this.http.get(`${environment.apiURL}/profile`, headers)
    .pipe(
      tap((a: any) => {
        if (a.data && a.data.role && a.data.role === "student") {
          this.loader.close();
          this.signout();
          this.signingIn = false;
          return false;
        } else {
          this.setUser(a.data)
        }
      }),
      catchError((error) => {
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public updateProfile(data) {
    this.loader.open();
    this.signingIn = true;
    return this.http
      .post(`${environment.apiURL}/self-update`, data, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        tap((a) => console.log(a)),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    localStorage.clear();
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.ls.setItem(this.JWT_TOKEN, token);
    this.setUser(user);
  }
  
  setUser(user: User) {
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.APP_USER, user);
    if (!user || !user.permissions) {
      localStorage.setItem("permissions", JSON.stringify([]));
      return;
    } 
    localStorage.setItem("permissions", JSON.stringify(user.permissions));
  }

  setToken(token: String, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
  }

  getRoles(): Observable<{ data: Role[] }> {
    return this.http
      .get<any>(`${environment.apiURL}/roles`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getPermissions(): Observable<{ data: Permission[] }> {
    return this.http
      .get<any>(`${environment.apiURL}/permissions`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  updatePermissions(
    role_id: number,
    data: { permissions: string[] }
  ): Observable<{ data: Role }> {
    this.loader.open();

    return this.http
      .put<any>(
        `${environment.apiURL}/roles/${role_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
