import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AlertService } from "./alert.service";
import { PaginatorService } from "./paginator.service";
import { Paginator } from "../models/paginator.model";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsExcel = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
    responseType: "blob" as "json",
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getPayments(paginator: Paginator = Paginator.getDefault(), params: any): any {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }

    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    this.loader.open();
    return this.http.get<any>(`${environment.apiURL}/payments`, params).pipe(
      tap((e) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  reportPayments(
    paginator: Paginator = Paginator.getDefault(),
    params: any
  ): any {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }

    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    this.loader.open();
    return this.http
      .get<any>(`${environment.apiURL}/reports-payments`, params)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  reportPaymentsDownload(params: any): any {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }

    this.loader.open();
    return this.http
      .post<any>(
        `${environment.apiURL}/reports-payments-download`,
        params,
        this.httpOptionsExcel
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getPaymentsVouchers(user_id) {
    let url = `${environment.apiURL}/payments-vouchers?user_id=${user_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  deletePayment(payment_id: number) {
    let url = `${environment.apiURL}/payment-file/${payment_id}`;

    return this.http.delete<any>(url, this.httpOptions);
  }
}
