import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class GroupUserService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsExcel = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
    responseType: 'blob' as 'json',
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}

  public getCoursesPaginator(
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    return this.http.get(`${environment.apiURL}/courses`, params).pipe(
      tap((_) => this.loader.close()),
      tap((res: any) => console.log(res)),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public getUsers(role, filter) {
    console.log('AAA')
    console.log(role)
    console.log(filter)
    return this.http
      .get(
        `${environment.apiURL}/users?role=${role}&&name=${filter}`,
        this.httpOptions
      )
      .pipe(
        tap((a: any) => console.log(a)),
        map((a: any) => a.data),
        map((a: any) => {
          let results = [];
          a.forEach((element) => {
            let nameComplete = element.first_name + element.last_name;
            if (
              element.role == role &&
              nameComplete.toLowerCase().includes(filter.toLowerCase())
              ) {
                results.push(element);
              }
            });
            return results;
          }),
          
          tap((a: any) => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getDocentesSearch(filterValue: string) {
    return this.http
      .get(`${environment.apiURL}/groups-users`, this.httpOptions)
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (
              element.type == "teacher" &&
              element.user.first_name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            ) {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')

          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getStudentsSearch(filterValue: string) {
    return this.http
      .get(`${environment.apiURL}/groups-users`, this.httpOptions)
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (
              element.type == "student" &&
              element.user.first_name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            ) {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getDocentes(group_id) {
    return this.http
      .get(
        `${environment.apiURL}/groups-users?type=teacher&group_id=${group_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (
              element.subtype == "teacher" ||
              element.subtype == "tutor" ||
              element.subtype == "instructor" ||
              element.subtype == "facilitator" ||
              element.subtype == "academic_tutor" ||
              element.subtype == "umbrella_tutor" ||
              element.subtype == "administrative_tutor"
            ) {
              onlydocentes.push(element);
            }
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getStudents(group_id) {
    return this.http
      .get(
        `${environment.apiURL}/groups-users?group_id=${group_id}`,
        this.httpOptions
      )
      .pipe(
        // tap(a => console.log(a)),
        map((a: any) => a.data),
        // tap(a => console.log(a)),
        map((a: any) => {
          let onlydocentes = [];
          a.forEach((element) => {
            if (element.type == "student") {
              onlydocentes.push(element);
            }
            // console.log(onlydocentes)
          });

          return onlydocentes;
        }),
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getStudentsPaginator(
    group_id,
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    //console.log(params)
    params = { ...this.httpOptions, params };
    return this.http
      .get(`${environment.apiURL}/groups-users?group_id=${group_id}`, params)
      .pipe(
        // map((a: any) => a.data),
        catchError((error) => {
          // console.log('error')
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  // getStudentsPaginator(
  //   group_id,
  //   paginator: Paginator = Paginator.getDefault(),
  //   params: {}
  // ) {
  //   params = this.paginatorService.transformParams(paginator, params);
  //   //console.log(params)
  //   params = { ...this.httpOptions, params };
  //   return this.http
  //     .get(`${environment.apiURL}/groups-users?group_id=${group_id}`, params)
  //     .pipe(
  //       // tap(a => console.log(a)),
  //       map((a: any) => a.data),
  //       // tap(a => console.log(a)),
  //       map((a: any) => {
  //         let onlydocentes = [];
  //         a.forEach((element) => {
  //           if (element.type == "student") {
  //             onlydocentes.push(element);
  //           }
  //           // console.log(onlydocentes)
  //         });

  //         return onlydocentes;
  //       }),
  //       // tap(a => console.log(a)),
  //       debounceTime(500),
  //       catchError((error) => {
  //         // console.log('error')
  //         this.loader.close();
  //         this.alertService.showErrors(error);
  //         return throwError(error);
  //       })
  //     );
  // }

  storeDocente(body) {
    return this.http
      .post(`${environment.apiURL}/groups-users`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeEstudiante(body) {
    return this.http
      .post(`${environment.apiURL}/groups-users`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteDocente(id) {
    return this.http
      .delete(`${environment.apiURL}/groups-users/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteEstudiante(id) {
    return this.http
      .delete(`${environment.apiURL}/groups-users/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getStudentInGroup(id) {
    return this.http
      .get(`${environment.apiURL}/groups-users/${id}`, this.httpOptions)
      .pipe(
        // tap(a => console.log(a)),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getAttendanceItems(
    params: any
  ) {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }

    params = { ...this.httpOptions, params };
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/reports-attendances`, params)
      .pipe(
        tap((e) => this.loader.close()),
        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  reportAttendancesDownload(
    params: any
  ): any {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }

    this.loader.open();
    return this.http
      .post<any>(`${environment.apiURL}/reports-attendances-download`, params, this.httpOptionsExcel)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
