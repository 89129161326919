import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-in-course',
  templateUrl: './header-in-course.component.html',
  styleUrls: ['./header-in-course.component.scss']
})
export class HeaderInCourseComponent {

  @Input() data: any;
  @Input() urlBack: string;
  @Input() urlDetail: string;
  @Input() showNavegation: boolean = true;

  constructor(private router: Router) {}

  goHome() {
    this.router.navigateByUrl(`${this.urlBack}`);
  }

  goDetail() {
    this.router.navigateByUrl(`${this.urlDetail}`);
  }
}
