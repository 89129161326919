import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Score } from "../models/score.model";
import { PaginatorResponse } from "../models/paginator.model";
import { Paginator } from "app/shared/models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";
import { Quiz } from "../models/quiz.model";
import { QuizValidation } from "../models/quiz_validation.model";

@Injectable({
  providedIn: "root",
})
export class QuizService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}

  public getQuiz(
    course_id,
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/quizzes?course_id=${course_id}`, params)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateQuiz(quiz_id: number, data: {}): Observable<{ data: Quiz }> {
    this.loader.open();
    return this.http
      .put<any>(
        `${environment.apiURL}/quizzes/${quiz_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);

          return throwError(error);
        })
      );
  }

  public showQuiz(quiz_id: number): Observable<{ data: Quiz }> {
    return this.http
      .get<any>(`${environment.apiURL}/quizzes/${quiz_id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);

          return throwError(error);
        })
      );
  }

  public storeQuiz(data): Observable<{ data: Quiz }> {
    return this.http
      .post(`${environment.apiURL}/quizzes`, data, this.httpOptions)
      .pipe<any>(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteQuiz(quiz_id: Number) {
    return this.http
      .delete(`${environment.apiURL}/quizzes/${quiz_id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  verifyQuiz(quiz_id: number): Observable<{ data: QuizValidation }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/verify-quizzes/${quiz_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
