import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { UsersService } from "app/shared/services/users.service";
import { Subscription } from "app/shared/models/subscription.model";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MY_FORMATS } from "app/shared/components/tab-billing/tab-billing.component";
import { BillingService } from "app/shared/services/billing.service";
import { PageEvent } from "@angular/material/paginator";
import { Paginator } from "app/shared/models/paginator.model";


@Component({
  selector: 'app-select-billing',
  templateUrl: './select-billing.component.html',
  styleUrls: ['./select-billing.component.scss']
})
export class SelectBillingComponent implements OnInit {
  public billings = [];
  private queryParams: {} = {};
  public per_page: number = 10;
  public page: number = 1;
  public pageSizeOptions = [5, 10, 25, 50];
  pageEvent: PageEvent;
  public paginator: Paginator = Paginator.getDefault();



  constructor(  
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectBillingComponent>,
    private billingService: BillingService,
  ) { }

  ngOnInit(): void {
    let params = {
      ...this.queryParams,
    };

    this.billingService.getBillingPaginator(this.data.course_id,this.paginator,params).subscribe((res: any) => {
      this.billings = res.data;
      this.paginator = new Paginator(res.meta);

      this.billings.forEach((element) => {
        if (element.id == this.data.selectedBilling.id) {
          element.selected = true;
        }
      });
    });
  }

  handleSelectBilling(row){
    this.dialogRef.close(row);
  }

}
