import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { PermissionUsersGuard } from "./shared/guards/permission-users.guard";

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "courses",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "courses",
        // canActivate: [PermissionUsersGuard],
        loadChildren: () =>
          import("./views/courses/courses.module").then((m) => m.CoursesModule),
        data: { title: "Cursos", breadcrumb: "INICIO" },
      },
      {
        path: "",
        loadChildren: () =>
          import("./views/administrador/administrador.module").then(
            (m) => m.AdministratorModule
          ),
        data: { title: "Administrador" },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        data: { title: "Perfil", breadcrumb: "PERFIL" },
      },
      {
        path: "others",
        loadChildren: () =>
          import("./views/others/others.module").then((m) => m.OthersModule),
        data: { title: "Others", breadcrumb: "OTHERS" },
      },
      {
        path: "search",
        loadChildren: () =>
          import("./views/search-view/search-view.module").then(
            (m) => m.SearchViewModule
          ),
      },
      {
        path: "messages",
        loadChildren: () =>
          import("./views/messages/messages.module").then(
            (m) => m.MessagesModule
          ),
      },
      {
        path: "journey",
        loadChildren: () =>
          import("./views/journeys/journeys.module").then(
            (m) => m.JourneysModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
