<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
  <h3 mat-dialog-title>Reserva de matrícula</h3>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap" fxLayout.lt-sm="column">
      <div fxFlex="100">
        <mat-form-field class="full-width">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5" formControlName="suspended_reason" placeholder="Motivo de la suspensión"></textarea>
          <mat-error *ngIf="suspended_reason.hasError('required')">
            El motivo de la suspensión es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field class="full-width">
          <input matInput [matDatepicker]="picker" name="title" formControlName="suspended_at"
            placeholder="Fecha de suspensión *" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="suspended_at.hasError('required')">
            Complete la fecha de suspensión
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close class="btn-danger mr-auto">Cerrar</button>
    <button mat-stroked-button color="success" class="btn-primary" type="submit">Enviar</button>
  </mat-dialog-actions>
</form>