import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Course } from "app/shared/models/course.model";
import { Group } from "app/shared/models/group.model";
import { Paginator } from "app/shared/models/paginator.model";
import { AlertService } from "app/shared/services/alert.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { CourseService } from "app/shared/services/course.service";
import { GroupService } from "app/shared/services/group.service";
import { UsersService } from "app/shared/services/users.service";
import { Payment } from 'app/shared/models/payment.model';
import { PaymentsService } from '../../services/payments.service';
import { Subscription } from "app/shared/models/subscription.model";
import { LearningPackage } from "app/shared/models/learning_package.model";

@Component({
    selector: "app-report-payments",
    templateUrl: "./report-payments.component.html",
  })
  export class ReportPaymentsComponent implements OnInit {
    @Input() learning_packages: LearningPackage[] = [];
    @Input() courses: Course[] = [];
    public paginator: Paginator = Paginator.getDefault();

    private queryParams: {} = {};
    public groups: Group[] = [];
    public per_page: number = 10;
    public page: number = 1;
    public pageSizeOptions = [5, 10, 25, 50];
    pageEvent: PageEvent;
    public payments: Payment[] = [];
    public course_id: string = null;
    public course_ids: Number[] = [];
    public group_id: number = null;
    public courseInput = null;
    public learning_package_id: number = null;

    constructor(
        private usersService: UsersService,
        public dialog: MatDialog,
        private router: Router,
        private loader: AppLoaderService,
        private alertService: AlertService,
        private jwAuth: JwtAuthService,
        private courseService: CourseService,
        private groupService: GroupService,
        private snack: MatSnackBar,
        private fb: FormBuilder,
        private paymentsServices: PaymentsService,
        private userService: UsersService,
    ) {}
    
    ngOnInit(): void {
    }

    getGroups(search?: string): void {
      let params = {};
      params = {
        title: search,
        without_pagination: true,
      };
      params = {
        ...params,
        ...this.queryParams,
      };

      this.groups = [];
      if (!this.course_id) {
        return;
      }
      this.groupService
        .getGroupsPaginator(this.course_id, this.paginator, params)
        .subscribe((response: any) => {
          this.groups = response.data;

          let group: Group = {
            id: 0,
            name: "Todos",
            is_default: false
          };

          this.groups.unshift(group);
        });
    }

    changeCourse(course_id: number) {
      if (course_id != 0) {
        this.course_id = `${course_id}`; 
        this.getGroups("");
      }
    }

    changeLearningPackage(learning_package_id: number) {
      if (learning_package_id != 0) {
        this.learning_package_id = learning_package_id;
        let learning_package = this.learning_packages.find(item => item.id === learning_package_id);    
        learning_package.items.forEach(item => {
          this.course_ids.push(item.course_id)       
        })
        this.course_id = this.course_ids.join(",")
      }
    }

    getPayments() {
      this.payments = [];
      this.paymentsServices.reportPayments(this.paginator, {
        course_ids: this.course_id,
        group_id: this.group_id,
        includes: "subscription"
      }).subscribe((response: any) => {
        this.loader.close();
        this.payments = response.data;
        this.paginator = new Paginator(response.meta);
      })
    }

    downloadPayments() {
      let data = {
        course_ids: this.course_id,
        group_id: this.group_id,
        includes: "subscription"
      };

      if (!this.group_id) {
        delete data.group_id;
      }

      this.paymentsServices.reportPaymentsDownload(data).subscribe((res: any) => {
        this.downloadFile(res);
        this.alertService.showAlert(
          this.alertService.messageSuccess,
          this.alertService.typeSuccess
        );
      });
    }

    clearPaymentFilters() {
      this.course_id = null;
      this.course_ids = null;
      this.courseInput = null;
      this.getPayments();
    }

    get length() {
      return this.paginator.total;
    }

    handlePage(e: PageEvent) {
      this.per_page = e.pageSize;
      this.page = e.pageIndex + 1;

      this.paginator.perPage = this.per_page;
      this.paginator.page = this.page;

      this.getPayments();
    }

    getCourse(payment: Payment) {
      if (payment.items.length === 0) {
        return;
      }

      let title = "";
      payment.items.forEach((item) => {
        if (item.model_type === 'courses') {
          title = item.course.title;
        } else if (item.subscription && item.model_type.includes("subscription")) {
          let sub:Subscription = item.subscription as Subscription        
          if(sub.course != null){
            let course: Course = sub.course as Course          
            title = course.title;
          } else {
            title = "--";
          }             
        }
      })

      return title;
    }

    downloadFile(data: Blob) {
      const url = window.URL.createObjectURL(new Blob([data], {type:'application/vnd.ms-excel'}));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Reporte_Pagos.xls');
      document.body.appendChild(link);
      link.click();
    }

    get ableDownload() {
      return this.userService.able("download_reports");
    }

    getTitle(learning_package: LearningPackage) {
      let title = learning_package.title;
      if (learning_package.template) {
        return `${learning_package.template.title}_${title}`;
      }
      return title;
    }

  }