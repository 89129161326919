<footer class="main-footer" style="margin-top: 2rem; padding-bottom: 1rem;display:flex; justify-content: center;">
    <div fxLayout="row wrap" class="d-flex">
        <div fxFlex="100"  fxFlex.sm="100" style="margin-bottom: rem; text-align: center;">

            <!-- <img src="https://logouss.s3.us-east-2.amazonaws.com/ulearning-removebg-preview.png" height="80px" alt="">
            <div style="display: flex; justify-content: center;">
    
            </div>
            <div style="display: flex; justify-content: center;">
                <h6>Sigue conectado:</h6>
            </div>
            <div style="text-align: center; justify-content: center;">

                <div>
                    <a href="https://www.facebook.com/ussipan" target="_blank">

                        <img src="../../../../assets/images/social-network/facebook.png" alt="" width="15px"
                            style="margin-right: 1rem;">
                    </a>

                    <a href="https://www.instagram.com/ussipan/" target="_blank">

                        <img src="../../../../assets/images/social-network/instagram.svg" alt="" width="15px"
                            style="margin-right: 1rem;">
                    </a>

                    <a href="https://www.youtube.com/c/ussipan" target="_blank">

                        <img src="../../../../assets/images/social-network/youtube.png" alt="" height="15px"
                            style="margin-right: 1rem;">
                    </a>

                    <a href="https://wa.me/969702671" target="_blank">

                        <img src="../../../../assets/images/social-network/whatsapp.png" alt="" width="15px"
                            style="margin-right: 1rem;">
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</footer>