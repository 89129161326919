import { Pipe, PipeTransform } from "@angular/core";

const DICTIONARY = {
  course: "Curso",
  path: "Ruta",
  package: "Paquete",
};
@Pipe({
  name: "classification",
})
export class ClassificationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return DICTIONARY[value];
  }
}
