<h4>Evaluaciones</h4>

<mat-card class="p-0">
    <mat-card-content class="p-0">
        <div fxLayout="row wrap" fxLayout.lt-sm="column">
            <div fxFlex="50" class="pr-16">
                <button class="button-7 bg-blue" style="margin-right: 0.5rem" (click)="updateAverage()">
                    Guardar o actualizar nota final
                </button>
            </div>
        </div>
        <hr>
        <ngx-datatable class="material ml-0 mr-0" [rows]="evaluations" columnMode="force" [headerHeight]="50"
        footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

            <ngx-datatable-column name="Nombre" [flexGrow]="6">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.name">{{ row?.name }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Nota" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [title]="row?.value">{{ row?.rating ? row?.rating : 'Sin asignar' }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Acción" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button class="button-7 bg-blue" style="margin-right: 0.5rem" (click)="goEvaluation(row)">
                        Ver nota
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </mat-card-content>
</mat-card>