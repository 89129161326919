<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
  <h3 mat-dialog-title>Datos del diploma</h3>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap" fxLayout.lt-sm="column">
      <div fxFlex="50" class="pr-16">
        <mat-form-field class="full-width">
          <input matInput name="name" [formControl]="name" placeholder="Nombre *" />
          <mat-error *ngIf="title.hasError('required')">
            Complete el nombre del estudiante
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="pr-16">
        <mat-form-field class="full-width">
          <input matInput name="title" [formControl]="title" placeholder="Curso *" />
          <mat-error *ngIf="title.hasError('required')">
            Complete el curso
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="pr-16">
        <mat-form-field class="full-width">
          <input matInput name="hours" [formControl]="hours" placeholder="Horas de aprendizaje" />
        </mat-form-field>
      </div>
      <div fxFlex="50" class="pr-16">
        <mat-form-field class="full-width">
          <input matInput [matDatepicker]="picker" name="date" [formControl]="date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="date.hasError('required')">
            Complete la fecha de emisión
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <mat-slide-toggle formControlName="toggle" (toggleChange)="toggleMail()"></mat-slide-toggle>
        <label for="">
          {{ sendMail ? "Enviar credenciales por mail" : "No enviar mail" }}
        </label>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn-danger mr-auto">Cerrar</button>
    <button mat-stroked-button color="success" class="btn-primary" type="submit">Enviar</button>
  </mat-dialog-actions>
</form>