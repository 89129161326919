import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class CouponsService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService,
    private router: Router
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  public getCoupons(params: {}) {
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/coupons`, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        map((a: any) => a.data),
        catchError((error) => {
          this.loader.close();
          //this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteCoupon(id) {
    this.loader.open();
    return this.http
      .delete(`${environment.apiURL}/coupons/${id}`, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeCoupon(body) {
    this.loader.open();
    return this.http
      .post(`${environment.apiURL}/coupons`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateCoupon(id, body) {
    this.loader.open();
    return this.http
      .put(`${environment.apiURL}/coupons/${id}`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
