export interface PaginatorResponse {
    current_page?: number;
    from?: number;
    last_page?: number;
    path?: number;
    per_page: number;
    total?: number;
}

export class Paginator {
    private _data: PaginatorResponse;
    private _page: number;

    constructor(data: PaginatorResponse, page: number | null = null) {
        this._data = data;
        this._page = page || data.current_page || 1;
    }

    static getDefault() {
        return new Paginator({
            per_page: 10,
        });
    }

    get perPage(): number {
        return this._data.per_page;
    }

    set perPage(newPerPage: number) {
        this._data.per_page = newPerPage;
    }

    get page(): number {
        return this._page || this._data.current_page || 1;
    }

    set page(newPage: number) {
        this._page = newPage;
    }

    get currentPage(): number {
        return this._data.current_page;
    }

    get lastPage(): number {
        return this._data.last_page;
    }

    get count(): number | null {
        return this._data.current_page || null;
    }

    get total(): number | null {
        return this._data.total || null;
    }
}
