import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { UsersService } from "app/shared/services/users.service";
import { Subscription } from "app/shared/models/subscription.model";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MY_FORMATS } from "app/shared/components/tab-billing/tab-billing.component";

export interface State {
  flag: string;
  name: string;
  population: string;
}

@Component({
  selector: "edit-certificate-modal",
  templateUrl: "edit-certificate-modal.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-PE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditCertificateModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;
  public sendMail: boolean = false;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Subscription,
    public dialogRef: MatDialogRef<EditCertificateModalComponent>,
    private fb: FormBuilder,
    private usersService: UsersService
  ) {}

  getDate() {
    return this.formatDate(new Date());
  }

  formatDate(date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }

  ngOnInit(): void {
    let title = "";
    let hours = "";
    console.log(this.data);
    if (this.data && this.data.classification === "course") {
      title = this.data.course.title;
      hours = this.data.course.self_study_hour.toString();
    } else if (this.data) {
      title = this.data.learning_package.title;
      hours = "";
    }
    this.form = new FormGroup({
      name: new FormControl(this.data ? this.data.user.name : "", [
        Validators.required,
        Validators.maxLength(255),
      ]),
      title: new FormControl(title, [
        Validators.required,
        Validators.maxLength(500),
      ]),
      period: new FormControl("", [Validators.maxLength(250)]),
      hours: new FormControl(hours, [
        this.data && this.data.classification === "course"
          ? Validators.required
          : Validators.minLength(0),
      ]),
      date: new FormControl(
        this.data && this.data.finished_at
          ? this.formatDate(new Date(this.data.finished_at))
          : this.getDate(),
        [Validators.required]
      ),
      rating: new FormControl(
        this.data && this.data.rating ? this.data.rating : 0,
        [Validators.required, Validators.min(0), Validators.max(20)]
      ),
    });
  }

  get f() {
    return this.form.controls;
  }

  get name() {
    return this.form.get("name");
  }

  get title() {
    return this.form.get("title");
  }

  get period() {
    return this.form.get("period");
  }

  get hours() {
    return this.form.get("hours");
  }

  get date() {
    return this.form.get("date");
  }

  get rating() {
    return this.form.get("rating");
  }

  onSubmit() {
    if (this.form.invalid) {
      this.name.markAsDirty();
      this.name.markAsTouched();
      this.title.markAsDirty();
      this.title.markAsTouched();
      this.period.markAsDirty();
      this.period.markAsTouched();
      this.hours.markAsDirty();
      this.hours.markAsTouched();
      this.date.markAsDirty();
      this.date.markAsTouched();
      this.rating.markAsDirty();
      this.rating.markAsTouched();
      return;
    }

    this.generateCertificate();
  }

  textChange($event) {
    let value = $event.target.value;
    console.log(value);
  }

  generateCertificate() {
    let data: any = {
      name: this.name.value,
      title: this.title.value,
      period: this.period.value,
      hours: this.hours.value,
      date: this.date.value,
      rating: this.rating.value,
      send_mail: this.sendMail,
    };

    this.loading = true;
    this.usersService.generateCertificate(this.data.id, data).subscribe(
      (response) => {
        this.loading = false;
        this.dialogRef.close(true);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  toggleMail() {
    this.sendMail = !this.sendMail;
  }
}
