<div class="conversations-hold ps">
    <mat-card class="mat-card mat-focus-indicator p-24 d-flex align-items-center secondary">
        <mat-card-content class="p-0" fxlayout="row">
            <div fxlayout="row" class="single-conversation sender ng-star-inserted"
                style="flex-direction: row; box-sizing: border-box; display: flex;">
                <a href="" class="toolbar-avatar online ng-star-inserted">
                    <img [src]="avatarUrl(item)" [alt]="getAuthor(item)">
                </a>
                <div>
                    <h5 class="chat-username text-muted ng-star-inserted">{{ getAuthor(item) }}</h5>
                    <h2 class="conversation-msg"> {{ getSubject(item) }} </h2>
                    <div class="conversation-msg" [innerHtml]="getContent(item)"> </div>
                </div>
            </div>
            <div fxLayout="row" fxLayout-align="end">
                <p class="chat-date text-muted ml-auto">{{ item?.published_at | date: 'dd/MM/YYYY HH:mm' }}</p>
            </div>
            <div fxLayout="row" fxLayout-align="end" *ngIf="!showForm">
                <button mat-button color="primary" type="button" (click)="toggleRead()">{{ getStatusMessage() }}</button>
                <button class="ml-auto" mat-button color="accent" type="button"
                    (click)="showForm = !showForm">Responder</button>
            </div>

            <mat-card *ngIf="showForm" class="full-width secondary">
                <mat-card-content class="p-0 full-width">
                    <form (ngSubmit)="onSubmit()" [formGroup]="form" autocomplete="off">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 1rem;">
                            <div fxFlex="100" class="pr-16">
                                <mat-form-field class="full-width">
                                    <input matInput formControlName="subject" name="document_number"
                                        placeholder="Título">
                                </mat-form-field>
                                <small
                                    *ngIf="subject.errors && (subject.dirty || subject.touched) && (subject?.errors?.required)"
                                    class="form-error-msg"> El título es requerido </small>
                            </div>

                            <div fxFlex="100" class="pr-16">
                                <ckeditor
                                    [config]="configCKEditor"
                                    formControlName="content"
                                    [editor]="Editor"
                                    id="content"
                                    name="content">
                                </ckeditor>
                            </div>
                            <div fxFlex="100" class="pr-16">
                                <small
                                *ngIf="content.errors && (content.dirty || content.touched) && (content?.errors?.required)"
                                class="form-error-msg"> El contenido es requerido </small>
                            </div>
                        </div>
                        <br/>
                        <div fxLayout="row" fxLayout-align="between">
                            <button mat-stroked-button type="button" color="danger" (click)="cancel()">Cancelar</button>
                            <button class="ml-auto" mat-stroked-button type="submit" color="success">Guardar</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="item?.replies.length > 0" class="full-width border-none">
                <app-forum-item (reloadHistory)="emitReload($event)" [item]="reply" *ngFor="let reply of item?.replies">
                </app-forum-item>
            </mat-card>
        </mat-card-content>
    </mat-card>
</div>