<mat-card class="p-0 tabresults">
    <mat-card-content class="p-0">
        <div class="section-btn">
            <button mat-stroked-button class="btn-primary" type="button" (click)="openDialog()">
                Nuevo mensaje
            </button>
            <!-- <h3 class="title" >{{ row?.user?.first_name }} {{ row?.user?.last_name }}</h3> -->

        </div>
        <ngx-datatable class="material ml-0 mr-0" [rows]="conversations" [columnMode]="'force'" [headerHeight]="50"
            footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">
            <ngx-datatable-column name="ID" [flexGrow]="1" [width]="10">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.id }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Autor" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.createdBy?.name }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Contenido" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.firstMessage?.content }}
                </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="Acciones" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button type="button" mat-icon-button color="accent" class="mr-8" (click)="goMessage(row)">
                        <mat-icon>link</mat-icon>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                    <div class="full-width paginator-container">
                        <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
                            aria-label="Select page" (page)="handlePage($event)">
                        </mat-paginator>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </mat-card-content>
</mat-card>