<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
    <h3 mat-dialog-title>Datos del diploma</h3>
    <mat-dialog-content class="mat-typography">
      <div fxLayout="row wrap" fxLayout.lt-sm="column">
        <div fxFlex="50" class="pr-16" >
            <mat-form-field class="full-width">
                <mat-select placeholder="Módulo" name="topic_id" formControlName="topic_id"  
                    matNativeControl required>
                    <mat-option [value]="topic?.id" *ngFor="let topic of listTopic" (click)="selectTopic(topic)">
                        {{ topic?.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="50" class="pr-16"> 
            <mat-form-field class="full-width">
                <mat-select placeholder="Sesión"name="topic_id2" formControlName="topic_id2"
                    matNativeControl required>
                    <mat-option [value]="session?.id" *ngFor="let session of selectedTopic">
                        {{session?.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="btn-danger mr-auto">Cerrar</button>
      <button mat-stroked-button color="success" class="btn-primary" type="submit">Enviar</button>
    </mat-dialog-actions>
  </form>