<form [formGroup]="form" (ngSubmit)="submit()">
  <!-- <form style="margin-top: 2rem" autocomplete="off">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Buscar usuario</mat-label>
        <input type="text" placeholder="Buscar.." aria-label="Curso" matInput (input)="textChange($event)" />
      </mat-form-field>
    </form> -->
  <h3 mat-dialog-title>{{ title }} <strong></strong></h3>
  <form class="example-form" *ngIf="!isEdit && showSearchBar && (isSupport || !to_support)">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Buscar usuario</mat-label>
      <input placeholder="Buscar.." matInput aria-label="State" [matAutocomplete]="auto" (input)="textChange($event)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of users" (click)="handleClick(user)">
          <span>{{user.name}}</span> |
          <small> {{user.email}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <mat-chip-list #chipList aria-label="Fruit selection" *ngIf="showSearchBar">
    <mat-chip *ngFor="let user of usersSelected" [selectable]="selectable" [removable]="removable"
      (removed)="remove(user)">
      <span>{{user.name}}</span>
      <mat-icon matChipRemove (click)="removeUser(user)">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <mat-dialog-content class="mat-typography">
    <mat-form-field class="w-100 mt-24">
      <mat-label>Mensaje</mat-label>
      <textarea placeholder="Agregue un mensaje..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3" class="w-100" formControlName="content"></textarea>

      <mat-error *ngIf="content.hasError('required')">
        Complete su mensaje
      </mat-error>
      <mat-error *ngIf="content.hasError('maxLength')">
        El contenido no puede tener más de 10,000 caracteres
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <div fxLayout="row wrap" *ngIf="!isEdit && (isSupport || !to_support)">
    <div fxFlex="100">
      <mat-slide-toggle formControlName="toggleBroadcast" (toggleChange)="toggleBroadcast()"></mat-slide-toggle>
      <label for="">
        {{ is_broadcast ? titleBroadcast : "Mensaje individual" }}
      </label>
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="!isEdit && (isSupport || !to_support)">
    <div fxFlex="100">
      <mat-slide-toggle formControlName="toggle" (toggleChange)="toggleSchedule()"></mat-slide-toggle>
      <label for="">
        {{ sendNow ? "Enviar ahora" : "Programar" }}
      </label>
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="!isEdit && (isSupport || !to_support)">
    <div fxFlex="100">
      <mat-slide-toggle formControlName="toggleReply" (toggleChange)="toggleReply()"></mat-slide-toggle>
      <label for="">
        {{ can_by_reply ? "Admite respuestas" : "No admite respuestas" }}
      </label>
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="!isEdit && (isSupport || !to_support)">
    <div fxFlex="100" *ngIf="usersSelected.length > 1 || is_broadcast">
      <mat-slide-toggle formControlName="toggleReplyToAuthor" (toggleChange)="toggleReplyToAuthor()"></mat-slide-toggle>
      <label for="">
        {{ reply_to_author ? "Solo puede ver los mensajes del autor" : "Puede ser todos los mensajes de los
        participantes" }}
      </label>
    </div>
  </div>
  <div class="mt-24" fxLayout="row wrap">
    <div fxFlex="50" *ngIf="!sendNow && (isSupport || !to_support)">
      <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Elija una fecha" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="50" *ngIf="!sendNow && (isSupport || !to_support)">
      <mat-form-field>
        <input matInput type="time" name="start" placeholder="Hora " formControlName="time" />
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mr-auto">Cerrar</button>
    <button type="submit" id="send" class="button-send" tooltip="Send" flow="left" [disabled]="!isValid()"><span
        class="material-icons headerIcon">send</span></button>
  </mat-dialog-actions>
</form>