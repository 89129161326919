
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertService } from "app/shared/services/alert.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Paginator } from "app/shared/models/paginator.model";
import { PageEvent } from "@angular/material/paginator";
import { TopicService } from "app/shared/services/topic.service";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
@Component({
  selector: 'app-select-topic-modal',
  templateUrl: './select-topic-modal.component.html',
  styleUrls: ['./select-topic-modal.component.scss']
})
export class SelectTopicModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  listTopic: any;
  public idCourse;
  selectedTopic: any[] = [];
  constructor(
    private jwAuth: JwtAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alertService: AlertService,
    private topicService: TopicService,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    private loader: AppLoaderService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({})


    this.topicService.getTopics(this.idCourse).subscribe((res: any) => {
      this.listTopic = res;
    },err => console.log('ERROR'));
  }
  selectTopic(topic: any) {
  
    this.selectedTopic = topic.items.filter((e) => e.icon == "list");
  }

  onSubmit() {

  }

}
