import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Evaluation } from "app/shared/models/evaluation.model";
import { ConfirmDialogComponent } from "app/views/courses/teachers-students/confirm-dialog/confirm-dialog.component";
import { SubscriptionService } from "../../services/subscription.service";

@Component({
  selector: "app-evaluation-rating-modal",
  templateUrl: "./evaluation-rating-modal.component.html",
  styleUrls: ["./evaluation-rating-modal.component.scss"],
})
export class EvaluationRatingModalComponent implements OnInit {
  public evaluations: Evaluation[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.evaluations = this.data.evaluations;
  }

  goEvaluation(evaluation: Evaluation) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/courses/${evaluation.course_id}/groups/${evaluation.group_id}/evaluations/${evaluation.id}/scores`,
      ])
    );

    window.open(url, "_blank");
  }

  updateAverage() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subscriptionService
          .updateAverageSubscription(this.data.subscription_id)
          .subscribe((response) => {
            console.log(response);
          });
      }
    });
  }
}
