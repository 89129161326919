import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { FileTypesPipe } from './file-types.pipe';
import { StatusPipe } from './status.pipe';
import { ModelTypePipe } from './model-type.pipe';
import { ClassificationPipe } from './classification.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  FileTypesPipe,
  StatusPipe,
  ModelTypePipe,
  ClassificationPipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}