import { Component, OnInit, Inject } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Paginator } from "app/shared/models/paginator.model";

import { Subscription } from "app/shared/models/subscription.model";
import { CourseService } from "app/shared/services/course.service";
import { GroupService } from "app/shared/services/group.service";
import { CustomMatPaginatorIntl } from "app/views/courses/utils/paginator-es";



@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: new CustomMatPaginatorIntl() },
  ],
})
export class SelectGroupComponent implements OnInit {
  public paginator: Paginator = Paginator.getDefault();
  private queryParams: {} = {};
  public filteredOptions: any[] = [];
  public per_page: number = 10;
  public page: number = 1;
  public pageSizeOptions = [5, 10, 25, 50];
  pageEvent: PageEvent;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Subscription,
    public dialogRef: MatDialogRef<SelectGroupComponent>,
    public courseService: CourseService,
    public groupService: GroupService,
    

  ) { 
    let params = {
      ...this.queryParams,
    };
    groupService.getGroupsPaginator(data.course_id,this.paginator, params).subscribe((res: any) => {
        res.data.forEach(element => {
            if(element.id == data.id){
              element.selected = true;
            } 
        });
       this.filteredOptions = res.data;
       this.paginator = new Paginator(res.meta);
    })

  }
  get length() {
    return this.paginator.total;
  }
  ngOnInit(): void {
    console.log(this.data)
  }

  seleccionar(row) {
    this.dialogRef.close(row);
  }

}
