import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { BillingService } from "app/shared/services/billing.service";
import { AlertService } from "app/shared/services/alert.service";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CourseService } from "app/shared/services/course.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { DialogConfirmComponent } from "app/shared/components/dialog-confirm/dialog-confirm.component";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import { CouponsService } from "app/shared/services/coupons.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD/MM/YYYY", // this is the format showing on the input element
    monthYearLabel: "MMMM YYYY", // this is showing on the calendar
  },
};
@Component({
  selector: "app-tab-billing",
  templateUrl: "./tab-billing.component.html",
  styleUrls: ["./tab-billing.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-PE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TabBillingComponent implements OnInit {
  @Input() itemFormBilling: FormGroup;

  public dataNavigation: any;
  public idGroup;
  public isEdit: Boolean = false;
  public idCourse;
  // public itemFormBilling: FormGroup;

  public listBillings = [];
  public editedBilling = false;
  billingToedit;

  constructor(
    private jwAuth: JwtAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private billingService: BillingService,
    private alertService: AlertService,
    private courseService: CourseService,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    public couponsService: CouponsService
  ) {
    this.idCourse = route.snapshot.params.course_id;
    this.loader.close();
  }
  user;
  permissionsPayments = JSON.parse(localStorage.getItem("permissions")).filter(
    (e) => e.includes("payments")
  );

  ngOnInit() {
    let object: any;
    this.buildItemFormBilling(null);
    this.getBilling();
    this.getCoupons();

    // this.buildItemFormBilling(null);
    // console.log(JSON.parse(localStorage.getItem('itemBilling')).data.payload)
  }

  ngOnDestroy() {
    // localStorage.removeItem('itemBilling')
  }

  public coupons = [];

  getCoupons() {
    this.couponsService.getCoupons({}).subscribe((res) => {
      this.coupons = res.filter((item) => {
        return !item.is_out_of_date;
      });
    });
  }

  buildItemFormBilling(item) {
    const defaultItem = {
      course_id: this.idCourse,
      amount: "",
      cycle: "",
      number_of_payments: "",
      is_default: false,
      is_special_amount: false,
      external_id: "",
      coupon_id: null,
    };

    const formValues = item ? { ...defaultItem, ...item } : defaultItem;

    this.idCoupon = formValues.coupon_id;

    this.itemFormBilling = this.fb.group({
      course_id: [formValues.course_id, Validators.required],
      amount: [formValues.amount, Validators.required],
      cycle: [formValues.cycle, Validators.required],
      number_of_payments: [formValues.number_of_payments, Validators.required],
      is_default: [formValues.is_default],
      is_special_amount: [formValues.is_special_amount],
      external_id: [formValues.external_id],
      coupon_id: [formValues.coupon_id],
    });

    console.log(this.itemFormBilling);
  }

  nonNegative() {
    if (this.itemFormBilling.value.cycle < 0) {
      this.snack.open("Solo valores positivos", "OK", {
        duration: 1000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });

      this.itemFormBilling.controls.cycle.setValue(0);
    }
  }

  updateBilling() {
    if (
      this.permissionsPayments.filter((e) => e.includes("create")).length > 0
    ) {
      const dialogRef = this.dialog.open(DialogConfirmComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingService
            .updateBilling(this.billingToedit.id, this.itemFormBilling.value)
            .subscribe((res) => {
              this.getBilling();
              this.alertService.showAlert(
                this.alertService.messageSuccess,
                this.alertService.typeSuccess
              );
            });
        }
      });
    } else {
      this.alertService.showAlert(
        this.alertService.messagePermission,
        this.alertService.typeSuccess
      );
    }
  }

  clearFormBilling() {
    this.editedBilling = false;
    // this.buildItemFormBilling(null);

    this.itemFormBilling = this.fb.group({
      course_id: [this.idCourse],
      amount: ["", Validators.required],
      cycle: ["", Validators.required],
      number_of_payments: ["", Validators.required],
      is_default: [false],
      is_special_amount: [false],
      external_id: [""],
      coupon_id: [null],
    });

    this.itemFormBilling.markAsUntouched();
    this.itemFormBilling.markAsPristine();
    this.itemFormBilling.controls["amount"].clearValidators();
    this.itemFormBilling.controls["cycle"].clearValidators();
    this.itemFormBilling.controls["number_of_payments"].clearValidators();
  }

  getBilling() {
    // this.loader.open();
    this.billingService.getBilling(this.idCourse).subscribe((res: any) => {
      this.listBillings = res.data;

      // this.buildItemFormBilling(null);
      this.itemFormBilling = this.fb.group({
        course_id: [this.idCourse],
        amount: ["", Validators.required],
        cycle: ["", Validators.required],
        number_of_payments: ["", Validators.required],
        is_default: [false],
        is_special_amount: [false],
        external_id: [""],
        coupon_id: [null],
      });

      this.editedBilling = false;
    });

    this.clearFormBilling();
  }

  submitBilling() {
    if (
      this.permissionsPayments.filter((e) => e.includes("create")).length > 0
    ) {
      const dialogRef = this.dialog.open(DialogConfirmComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingService
            .storeBilling(this.itemFormBilling.value)
            .subscribe((res) => {
              this.alertService.showAlert(
                this.alertService.messageSuccess,
                this.alertService.typeSuccess
              );
              this.getBilling();
            });
        }
      });
    } else {
      this.alertService.showAlert(
        this.alertService.messagePermission,
        this.alertService.typeSuccess
      );
    }
  }

  editBilling(row) {
    if (
      this.permissionsPayments.filter((e) => e.includes("update")).length > 0
    ) {
      console.log(row);
      this.editedBilling = true;
      this.billingToedit = row;
      //console.log(row)
      // this.buildItemFormBilling(row);

      this.itemFormBilling = this.fb.group({
        course_id: [row.course_id || 0, Validators.required],
        amount: [row.amount || "", Validators.required],
        cycle: [row.cycle || "", Validators.required],
        number_of_payments: [row.number_of_payments || "", Validators.required],
        is_default: [row.is_default || false],
        is_special_amount: [row.is_special_amount || false],
        external_id: [row.external_id || ""],
        coupon_id: [row.coupon_id || null],
      });
    } else {
      this.alertService.showAlert(
        this.alertService.messagePermission,
        this.alertService.typeSuccess
      );
    }
  }

  deleteBilling(row) {
    if (
      this.permissionsPayments.filter((e) => e.includes("destroy")).length > 0
    ) {
      const dialogRef = this.dialog.open(DialogConfirmComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingService.deleteBilling(row.id).subscribe((res) => {
            this.alertService.showAlert(
              this.alertService.messageSuccess,
              this.alertService.typeSuccess
            );
            this.getBilling();
          });
        }
      });
    } else {
      this.alertService.showAlert(
        this.alertService.messagePermission,
        this.alertService.typeSuccess
      );
    }
  }
  public idCoupon = 0;

  selectCoupon(coupon) {
    if (!coupon) {
      this.idCoupon = null;
      return;
    }
    this.idCoupon = coupon.id;
  }
}
