import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "app/shared/services/alert.service";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import * as countriesJSON from "assets/json/countries.json";
import { Country } from "../models/country.model";
import { User } from "../models/user.model";
import { Permission, Role } from "../models/permission.model";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  JWT_TOKEN = "JWT_TOKEN";
  PERMISSIONS = "permissions";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsExcel = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
    responseType: "blob" as "json",
  };

  httpPDFOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/pdf",
      Authorization: `Bearer ${this.getJwtToken()},`,
      responseType: "arraybuffer",
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private alertService: AlertService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private paginatorService: PaginatorService
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  public getUser(id, params: {} = {}) {
    params = {...this.httpOptions, params}
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/users/${id}`, params)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getUsers(filterValue, params: {} = {}) {
    let url = `${environment.apiURL}/users`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getJwtToken()}`,
        }),
        params: params,
      })
      .pipe(
        tap((_) => this.loader.close()),
        map((a: any) => a.data),
        map((a: any) => {
          let results = [];
          a.forEach((element) => {
            if (
              element.first_name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            ) {
              results.push(element);
            }
          });
          return results;
        }),

        debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getUsersPaginator(
    paginator: Paginator = Paginator.getDefault(),
    params: any
  ) {
    if (!!!params.role || params.role === "null") {
      delete params.role;
    }
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };

    this.loader.open();
    return this.http.get(`${environment.apiURL}/users`, params).pipe(
      tap((_) => this.loader.close()),
      tap((res: any) => console.log(res)),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public suspendUser(id) {
    this.loader.open();
    return this.http
      .patch(`${environment.apiURL}/users/${id}/suspend`, [], this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public reactivateUser(id) {
    //this.loader.open();
    return this.http
      .patch(
        `${environment.apiURL}/users/${id}/reactivate`,
        [],
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  deleteUser(id: number) {
    this.loader.open();
    return this.http
      .delete(`${environment.apiURL}/users/${id}`, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeUser(body) {
    this.loader.open();
    return this.http
      .post(`${environment.apiURL}/register`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateUsers(id, body) {
    return this.http
      .put(`${environment.apiURL}/users/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getSubscriptions(params: {} = {}): Observable<any> {
    params = { ...params, without_pagination: true };

    return this.getSubscriptionsPagination(Paginator.getDefault(), params);
  }

  public getSubscriptionsPagination(
    paginator: Paginator = Paginator.getDefault(),
    params: any
  ) {
    if (!params.course_id) {
      delete params.course_id;
    }
    if (!params.group_id) {
      delete params.group_id;
    }
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    return this.http.get(`${environment.apiURL}/subscriptions`, params).pipe(
      catchError((error) => {
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  public getRoles(): Observable<{ data: Role[] }> {
    return this.http
      .get<any>(`${environment.apiURL}/roles`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  allPermissions(): Observable<{ data: Permission[] }> {
    return this.http
      .get<any>(`${environment.apiURL}/permissions`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getSubscription(id) {
    return this.http
      .get(
        `${environment.apiURL}/subscriptions/${id}?includes=learning_package`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getSubscriptionsByText(text) {
    return this.http
      .get(`${environment.apiURL}/subscriptions`, this.httpOptions)
      .pipe(
        map((e: any) => e.data),
        map((e) => {
          let array = [];
          e.forEach((element) => {
            if (
              element.user.last_name.toLowerCase().includes(text.toLowerCase())
            ) {
              array.push(element);
            }
          });
          return array;
        }),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public editSubscription(id: any, body: any) {
    this.loader.open();
    return this.http
      .put(`${environment.apiURL}/subscriptions/${id}`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeSubscriptions(body) {
    this.loader.open();
    return this.http
      .post(`${environment.apiURL}/subscriptions`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public reportSubscriptions(body) {
    this.loader.open();
    return this.http
      .post<any>(
        `${environment.apiURL}/reports-subscriptions-download`,
        body,
        this.httpOptionsExcel
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public reportUsers(body) {
    this.loader.open();
    return this.http
      .post<any>(
        `${environment.apiURL}/reports-users-download`,
        body,
        this.httpOptionsExcel
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteSubscriptions(id) {
    //this.loader.open();
    return this.http
      .delete(`${environment.apiURL}/subscriptions/${id}`, this.httpOptions)
      .pipe(
        //tap(e => this.loader.close()),
        catchError((error) => {
          this.loader.close();

          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public changeUserSubtype(id, subtype) {
    this.loader.open();
    return this.http
      .patch(
        `${environment.apiURL}/groups-users/${id}/subtype`,
        { subtype },
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public forgotPassword(email: string) {
    this.loader.open();
    return this.http
      .post(
        `${environment.apiURL}/forgot-password`,
        { email: email },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  resetPassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ): Observable<{ data: any }> {
    this.loader.open();
    return this.http
      .post<any>(
        `${environment.apiURL}/self-reset-password`,
        { email, password, password_confirmation, token },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  generateCertificate(
    subscription_id: number,
    data: any[]
  ): Observable<{ data: any }> {
    let url = `${environment.apiURL}/certificates/${subscription_id}`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  generateDegree(
    subscription_id: number,
    data: any[]
  ): Observable<{ data: any }> {
    let url = `${environment.apiURL}/degrees/${subscription_id}`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  generateRecord(
    subscription_id: number,
    data: any[]
  ): Observable<{ data: any }> {
    let url = `${environment.apiURL}/records/${subscription_id}`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  getFiles(subscription_id: number): Observable<{ data: any }> {
    let url = `${environment.apiURL}/files/${subscription_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  downloadFile(
    id: number
  ): Observable<{ data: { file: string; filename: string } }> {
    let url = `${environment.apiURL}/download-file/${id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  deleteFile(id: number): Observable<{ data: any }> {
    let url = `${environment.apiURL}/files/${id}`;

    return this.http.delete<any>(url, this.httpOptions);
  }

  getPermissions() {
    let permissions = localStorage.getItem(this.PERMISSIONS);
    if (permissions) {
      return JSON.parse(localStorage.getItem(this.PERMISSIONS));
    }

    return [];
  }

  getParticipants(user_ids: number[]): Observable<{ data: User[] }> {
    let url = `${environment.apiURL}/messages-participants?ids=${user_ids}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  able(permission_name: string) {
    let permissions = this.getPermissions();
    return (
      permissions.findIndex((permission) => {
        return permission === permission_name;
      }) > -1
    );
  }
}
