import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private paginatorService: PaginatorService,
    private alertService: AlertService
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  public getGroups(course_id) {
    return this.http
      .get(
        `${environment.apiURL}/groups?course_id=${course_id}`,
        this.httpOptions
      )
      .pipe(
        //tap( a => console.log(a)),
        //debounceTime(500),
        map((res: any) => res.data),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getGroupsPaginator(
    course_id,
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ) {
    params = this.paginatorService.transformParams(paginator, params);
    // console.log(params)
    params = { ...this.httpOptions, params };
    return this.http
      .get(
        `${environment.apiURL}/groups?course_id=${course_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getGroup(group_id) {
    // return this.http.get(`${environment.apiURL}/groups/${group_id}`,this.httpOptions)
    return this.http
      .get(`${environment.apiURL}/groups/${group_id}`, this.httpOptions)
      .pipe(
        map((res: any) => res.data),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateGroup(id, body) {
    console.log(body);
    return this.http
      .put(`${environment.apiURL}/groups/${id}`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);

          return throwError(error);
        })
      );
  }

  public reactivateGroup(id) {
    this.loader.open();
    return this.http
      .patch(`${environment.apiURL}/groups/${id}/reactivate`, [], this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public suspendGroup(id) {
    this.loader.open();
    return this.http
      .patch(`${environment.apiURL}/groups/${id}/suspend`, [], this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeGroup(body) {
    console.log(body);
    return this.http
      .post(`${environment.apiURL}/groups`, body, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteGroup(id) {
    return this.http
      .delete(`${environment.apiURL}/groups/${id}`, this.httpOptions)
      .pipe(
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public getBilling(id) {
    this.loader.open();
    return this.http
      .get(`${environment.apiURL}/billings?user_id=${id}`, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        tap((a) => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeBilling(body) {
    this.loader.open();
    return this.http
      .post(`${environment.apiURL}/billings`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public updateBilling(id, body) {
    this.loader.open();
    return this.http
      .put(`${environment.apiURL}/billings/${id}`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        //tap( a => console.log(a)),
        //debounceTime(500),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
