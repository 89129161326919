import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageService } from "../../services/message.service";
import { AlertService } from "../../services/alert.service";
import { UsersService } from "../../services/users.service";
import { Observable } from "rxjs";
import { User } from "../../models/user.model";
import { format } from "date-fns";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MY_FORMATS } from "app/shared/components/tab-billing/tab-billing.component";
import { Message } from "app/shared/models/messages.model";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

export interface State {
  flag: string;
  name: string;
  population: string;
}

@Component({
  selector: "edit-message-modal",
  templateUrl: "edit-message-modal.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-PE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditMessageModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;
  public users: User[] = [];
  public usersSelected: User[] = [];
  public sendNow: boolean = true;
  public isEdit: boolean = false;
  public title: string = "Seleccione usuarios";
  private message: Message = null;
  private course_id: number = null;
  private is_broadcast: boolean = false;
  private can_by_reply: boolean = true;
  private reply_to_author: boolean = true;
  public to_support: boolean = false;
  private user: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { isEdit: boolean; message: Message; course_id: number; to_support: boolean; },
    public dialogRef: MatDialogRef<EditMessageModalComponent>,
    private messageService: MessageService,
    private alertService: AlertService,
    private userService: UsersService,
    private confirmService: AppConfirmService,
    private jwtAuth: JwtAuthService,
  ) {}

  get showSearchBar() {
    return this.is_broadcast ? false : true;
  }

  get titleBroadcast() {
    return this.data && this.data.course_id
      ? "Mensaje para TODOS los integrantes DEL CURSO"
      : "Mensaje para TODOS los usuarios de LA PLATAFORMA";
  }

  ngOnInit(): void {
    let now = new Date();
    let published_at = now;
    if (this.data) {
      this.isEdit = this.data.isEdit;
      if (this.isEdit) {
        this.message = this.data.message;
        published_at = new Date(this.message.published_at);
        this.sendNow = false;
        this.title = "Edite su mensaje";
      } else {
        this.course_id = this.data.course_id;
      }

      if (this.data.to_support) {
        this.to_support = this.data.to_support;
      }

      this.user = this.jwtAuth.getUser();
    }
    if (!this.isSupport && this.to_support){
      this.title = "Mensaje a plataforma";
    }

    this.form = new FormGroup({
      content: new FormControl(
        this.data && this.data.isEdit && this.message.content
          ? this.message.content
          : "",
        [Validators.required, Validators.maxLength(10000)]
      ),
      date: new FormControl(
        this.data && this.data.isEdit && this.message.published_at
          ? published_at
          : now
      ),
      time: new FormControl(
        this.data && this.data.isEdit && this.message.published_at
          ? format(published_at, "HH:mm")
          : format(now, "HH:mm")
      ),
      toggle: new FormControl(!this.sendNow),
      toggleReply: new FormControl(this.can_by_reply),
      toggleBroadcast: new FormControl(this.is_broadcast),
      toggleReplyToAuthor: new FormControl(this.reply_to_author),
    });
  }

  get f() {
    return this.form.controls;
  }

  get content() {
    return this.form.get("content");
  }

  get date() {
    return this.form.get("date");
  }

  get time() {
    return this.form.get("time");
  }

  private isRefreshData: boolean = false;

  submit() {
    if (this.form.invalid) {
      this.content.markAsDirty();
      this.content.markAsTouched();
      return;
    }

    let user_ids: number[] = [];

    this.usersSelected.forEach((user: User) => {
      user_ids.push(user.id);
    });

    let published_at = null;

    if (this.date.value && this.time.value) {
      let date = format(new Date(this.date.value), "yyyy-MM-dd");
      let time = this.time.value;

      published_at = `${date} ${time}:00`;
    }

    if (this.isEdit) {
      this.messageService
        .updateMessage(this.message.id, {
          content: this.content.value,
          published_at: published_at,
          to_support: this.to_support
        })
        .subscribe((response) => {
          this.alertService.showAlert(
            this.alertService.typeSuccess,
            this.alertService.messageEditedSuccessfully
          );
          this.form.reset();
          this.dialogRef.close(!this.isRefreshData);
        });
    } else {
      let data = {
        course_id: this.course_id,
        content: this.content.value,
        user_ids: user_ids,
        uuid: null,
        published_at: published_at,
        is_broadcast: this.is_broadcast,
        can_by_reply: this.can_by_reply,
        reply_to_author: this.reply_to_author,
        to_support: this.to_support,
      };

      this.confirmService
        .confirm({ message: `¿Desea enviar el mensaje?` })
        .subscribe((response) => {
          if (response) {
            this.messageService.storeMessage(data).subscribe((response) => {
              this.alertService.showAlert(
                this.alertService.typeSuccess,
                this.alertService.messageSendSuccessfully
              );
              this.form.reset();
              this.dialogRef.close(!this.isRefreshData);
            });
          }
        });
    }
  }

  textChange($event) {
    let filter = {
      name: $event.target.value ? $event.target.value.toLowerCase() : "",
      course_id: this.course_id,
    };

    if (!filter.course_id) {
      delete filter.course_id;
    }

    this.userService.getUsers("", filter).subscribe((response) => {
      this.users = response;
    });
  }

  stateCtrl = new FormControl();
  filteredStates: Observable<State[]>;

  toggleBroadcast() {
    this.is_broadcast = !this.is_broadcast;
  }

  toggleSchedule() {
    this.sendNow = !this.sendNow;
  }

  toggleReply() {
    this.can_by_reply = !this.can_by_reply;
  }

  toggleReplyToAuthor() {
    this.reply_to_author = !this.reply_to_author;
  }

  handleClick(user: User) {
    this.usersSelected.push(user);
  }

  removeUser(user: User) {
    let index = this.usersSelected.findIndex((item) => item.id === user.id);
    console.log(index)
    console.log(this.usersSelected)
    console.log(user)

    this.usersSelected.splice(index, 1)
  }

  isValid() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.sendNow && (!!!this.date.value || !!!this.time.value)) {
      return false;
    }
    return true;
  }

  get isSupport() {
   return this.userService.able('update_supports') || this.user.is_support;
  }
}
