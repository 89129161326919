import { Pipe, PipeTransform } from "@angular/core";
const types = [
    {
      key: "subscriptions",
      value: "Matrícula",
    },
    {
        key: "courses",
        value: "Compra de curso",
      },
      {
        key: "enrollment",
        value: "Inscripción",
      },
      {
        key: "subscription_certificates",
        value: "Certificado",
      },
      {
        key: "subscription_records",
        value: "Constancia de estudios",
      },
    ];

@Pipe({
    name: "modelType",
  })
  export class ModelTypePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      let index = types.findIndex((types) => {
        return types.key === value;
      });
  
      if (index > -1) {
        return types[index].value;
      }
  
      return "";
    }
  }
  