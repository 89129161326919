import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  public displayName: string = "";
  public displayImage: string = "";

  constructor(
    private router: Router,
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService
  ) {}

  ngOnInit() {
    let userStorage = localStorage.getItem("APP_USER");
    this.displayName = userStorage ? JSON.parse(userStorage)?.first_name : "";
    this.displayImage =
      userStorage && JSON.parse(userStorage)?.avatar
        ? JSON.parse(userStorage)?.avatar?.original_url
        : "assets/images/face-7.jpg";

    let permissionsStorage = localStorage.getItem("permissions");
    let permissions = permissionsStorage ? JSON.parse(permissionsStorage) : [];

    let permissionsUser =
      permissions.filter((e) => e.includes("list_users")).length > 0 ? true : false;

    let permissionsSubscriptions =
      permissions.filter((e) => e.includes("subscriptions")).length > 0
        ? true
        : false;

    let permissionsEvents =
      permissions.filter((e) => e.includes("blogs")).length > 0 ? true : false;

    let permissionsCategories =
      permissions.filter((e) => e.includes("categories")).length > 0
        ? true
        : false;

    let permissionsReports =
        permissions.filter((e) => e.includes("list_reports")).length > 0 ? true : false;

    let permissionsPartners =
        permissions.filter((e) => e.includes("list_partners")).length > 0 ? true : false;

    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      if (permissionsUser == false) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Usuarios"),
          1
        );
      }

      if (permissionsSubscriptions == false) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Matrículas"),
          1
        );
      }

      if (permissionsEvents == false) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Eventos"),
          1
        );
      }

      if (permissionsCategories == false) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Configuración"),
          1
        );
      }

      if (!permissionsReports) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Reportes"),
          1
        );
      }

      if (!permissionsPartners) {
        menuItem.splice(
          menuItem.findIndex((x: any) => x.name == "Aliados"),
          1
        );
      }

      this.menuItems = menuItem;

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }

  goProfile() {
    this.router.navigateByUrl("/profile");
  }

  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }
}
