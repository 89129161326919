import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16">{{ data.message }}</div>
    <div mat-dialog-actions >
    <div style="width:100%;text-align:right">
    
      <button 
      type="button"
      class="button-7 bg-red" style="margin-right:0.5rem"
      (click)="dialogRef.close(false)">Cancelar</button>
      <button 
      type="button" 
      class="button-7 bg-green"
      (click)="dialogRef.close(true)">Aceptar</button>
      &nbsp;
    </div>
    
   
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
}