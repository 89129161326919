import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MY_FORMATS } from "app/shared/components/tab-billing/tab-billing.component";
import { Feedback } from "app/shared/models/feedback.model";
import { FeedbackService } from "../../services/feedback.service";
import { AppConfirmService } from "../../services/app-confirm/app-confirm.service";

export interface State {
  flag: string;
  name: string;
  population: string;
}

@Component({
  selector: "edit-feedback-modal",
  templateUrl: "edit-feedback-modal.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-PE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditFeedbackModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Feedback,
    public dialogRef: MatDialogRef<EditFeedbackModalComponent>,
    private feedbackService: FeedbackService,
    private confirmService: AppConfirmService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      comment: new FormControl(this.data ? this.data.comment : "", [
        Validators.required,
        Validators.maxLength(5000),
      ]),
      rating: new FormControl(this.data ? this.data.rating : "", [
        Validators.required,
        Validators.min(1),
        Validators.max(5),
      ]),
      in_store: new FormControl(this.data ? this.data.in_store : "", []),
    });
  }

  get f() {
    return this.form.controls;
  }

  get comment() {
    return this.form.get("comment");
  }

  get rating() {
    return this.form.get("rating");
  }

  get in_store() {
    return this.form.get("in_store");
  }

  onSubmit() {
    console.log("onSubmit");
    console.log(this.form);
    if (this.form.invalid) {
      this.comment.markAsDirty();
      this.comment.markAsTouched();
      this.rating.markAsDirty();
      this.rating.markAsTouched();
      this.in_store.markAsDirty();
      this.in_store.markAsTouched();
      return;
    }
    this.confirmService
      .confirm({ message: `¿Desea guardar los cambios?` })
      .subscribe((response) => {
        if (response) {
          this.updateFeedback();
        }
      });
  }

  updateFeedback() {
    let data: any = {
      comment: this.comment.value,
      rating: this.rating.value,
      in_store: this.in_store.value,
    };

    this.feedbackService
      .updateFeedback(this.data.id, data)
      .subscribe((response) => {
        this.dialogRef.close(true);
      });
  }
}
