<mat-card style="padding: 2rem;">
    <h3>Reporte - Asistencia</h3>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 2rem">
        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Curso" name="course_id" [(ngModel)]="course_id" matNativeControl
                (valueChange)="changeCourse($event)">
                    <mat-option [value]="null">
                        Todos
                    </mat-option>
                    <mat-option [value]="course?.id" *ngFor="let course of courses">
                        {{course?.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="100" class="pr-16">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-select placeholder="Grupo" name="group_id" [(ngModel)]="group_id" matNativeControl>
                    <mat-option [value]="group?.id" *ngFor="let group of groups">
                        {{group?.name}} {{group.code ? "("+group.code+")" : ""}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="section-btn mt-16">
        <button mat-stroked-button type="button" class="btn-warning" (click)="downloadPayments()" *ngIf="ableDownload">
            Descarga excel
        </button>
        <div>
            <button mat-stroked-button type="button" class="btn-danger mr-4" (click)="clearPaymentFilters()">
                Limpiar
            </button>
            <button mat-stroked-button type="button" class="btn-primary" (click)="getAttendanceItems()">
                Mostrar
            </button>
        </div>
    </div>

    <mat-card class="p-0 tabresults">
        <mat-card-content class="p-0">
        <ngx-datatable class="material ml-0 mr-0" [rows]="attendanceItems" [columnMode]="'force'" [headerHeight]="50"
            footerHeight="auto" [scrollbarH]="true" [limit]="50" [rowHeight]="50">

            <ngx-datatable-column name="Nombre" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.name }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Curso" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.title }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Grupo" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.code }}
            </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="F. Apertura" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.opened_at | date:"dd/MM/YYYY HH:mm"}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="F. Registro" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row?.updated_at | date:"dd/MM/YYYY HH:mm" }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Estado" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.status | status }}
            </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        </mat-card-content>
    </mat-card>
</mat-card>