import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Forum } from 'app/shared/models/messages.model';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { MessageService } from 'app/shared/services/message.service';
import { ConfirmDialogComponent } from 'app/views/courses/teachers-students/confirm-dialog/confirm-dialog.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-forum-item',
  templateUrl: './forum-item.component.html',
  styleUrls: ['./forum-item.component.scss']
})
export class ForumItemComponent implements OnInit {
  @Input() item: Forum;
  public editorData = ``;
  public form: FormGroup;
  public showForm: boolean = false;
  @Output() reloadHistory = new EventEmitter<boolean>();
  public configCKEditor = { toolbar: [ 'heading', '|',
    'fontfamily','fontsize',
    'alignment',
    'fontColor','fontBackgroundColor', '|',
    'bold', 'italic', 'custombutton', 'strikethrough','underline','subscript','superscript','|',
    'link','|',
    'outdent','indent','|',
    'bulletedList','numberedList','|',
    'code','codeBlock','|',
    'insertTable','|',
    'blockQuote','|',
    'undo','redo','|',
  ] }
  public Editor = ClassicEditor;

  constructor(
    private confirmService: AppConfirmService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      content: new FormControl("", [
        Validators.required,
        Validators.maxLength(10000),
      ]),
      subject: new FormControl("", [
        Validators.required,
        Validators.maxLength(255),
      ]),
    });
  }

  get content() {
    return this.form.get("content");
  }

  get subject() {
    return this.form.get("subject");
  }

  onContentChanged() {}
  onSelectionChanged() {}

  getAuthor(message: Forum) {
    return message?.send_by.name;
  }

  getSubject(message: Forum) {
    return message?.subject;
  }

  getContent(message: Forum) {
    return message?.content;
  }

  avatarUrl(message: Forum) {
    let avatar = message?.send_by.avatar;

    if (avatar && avatar.original_url) {
      let original_url = avatar.original_url;
      if (
        original_url.indexOf("http://") !== 0 &&
        original_url.indexOf("https://") !== 0
      ) {
        original_url = `http://${original_url}`;
      }
      return original_url;
    } else {
      return `assets/images/avatars/001-man.svg`;
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      console.log('Invalid')
      this.content.markAsDirty();
      this.content.markAsTouched();
      this.subject.markAsDirty();
      this.subject.markAsTouched();
      return;
    }

    this.confirmService
      .confirm({ message: `¿Desea enviar la respuesta?` })
      .subscribe((response) => {
        if (response) {
          this.messageService
            .storeForumReply(this.item.id, {
              subject: this.subject.value,
              content: this.content.value,
            })
            .subscribe((response) => {
              this.form.reset();
              this.showForm = false;
              this.emitReload(true);
            });
        }
      });
  }

  emitReload($event) {
    this.reload()
  }

  reload() {
    this.reloadHistory.emit(true);
  }

  cancel() {
    this.showForm = false;
    this.form.reset();
  }

  toggleRead() {
    let config = new MatDialogConfig();
    config = {
      height: "auto",
      width: "100vw",
      panelClass: "full-screen-modal",
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.messageService.updateMessageStatus(this.item.id, {status: this.item.status === 'read' ? 'unread' : 'read'})
        .subscribe((response: any) => {
          this.reload()
        })
      }
    })
  }

  getStatusMessage() {
    return this.item?.status === 'read' ? "Marcar como NO revisado" : "Marcar como revisado";
  }
}
