import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Paginator } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";



@Injectable({
    providedIn: "root",
})
export class BillingService {
    JWT_TOKEN = "JWT_TOKEN";
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getJwtToken()}`,
        }),
    };
    constructor(
        private ls: LocalStoreService,
        private http: HttpClient,
        private loader: AppLoaderService,
        private snack: MatSnackBar,
        private router: Router,
        private alertService: AlertService,
        private paginatorService: PaginatorService

    ) { }


    getJwtToken() {
        return this.ls.getItem(this.JWT_TOKEN);
    }

    public getBilling(course_id: number) {
        // this.loader.open();
        return this.http
            .get(`${environment.apiURL}/billings?course_id=${course_id}`, this.httpOptions)
            .pipe(
                // tap((e) => this.loader.close()),
                tap((a) => console.log(a)),
                //debounceTime(500),
                catchError((error) => {
                    this.loader.close();
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    public getBillingPaginator(
        course_id: number,
        paginator: Paginator = Paginator.getDefault(),
        params: {}

    ) {
        params = this.paginatorService.transformParams(paginator, params);
        params = { ...this.httpOptions, params };
        // this.loader.open();
        return this.http
            .get(`${environment.apiURL}/billings?course_id=${course_id}`, params)
            .pipe(
                tap((a) => console.log(a)),
                catchError((error) => {
                    this.loader.close();
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    public storeBilling(body) {
        this.loader.open();
        return this.http
            .post(`${environment.apiURL}/billings`, body, this.httpOptions)
            .pipe(
                tap((e) => this.loader.close()),
                //tap( a => console.log(a)),
                //debounceTime(500),
                catchError((error) => {
                    this.loader.close();
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    public updateBilling(id, body) {
        this.loader.open();
        return this.http
            .put(`${environment.apiURL}/billings/${id}`, body, this.httpOptions)
            .pipe(
                tap((e) => this.loader.close()),
                //tap( a => console.log(a)),
                //debounceTime(500),
                catchError((error) => {
                    this.loader.close();
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    public deleteBilling(id) {
        this.loader.open();
        return this.http.delete(`${environment.apiURL}/billings/${id}`, this.httpOptions)
            .pipe(
                tap(e => this.loader.close()),
                catchError((error) => {
                    this.loader.close();
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            )
    }
}
