import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Evaluation } from "../models/evaluation.model";
import { PaginatorResponse } from "../models/paginator.model";
import { Paginator } from "app/shared/models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class EvaluationService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService,
    private paginatorService: PaginatorService
  ) {}

  getEvaluations(
    paginator: Paginator = Paginator.getDefault(),
    params: {}
  ): Observable<{ data: Evaluation[]; meta: PaginatorResponse }> {
    params = this.paginatorService.transformParams(paginator, params);
    params = { ...this.httpOptions, params };
    return this.http.get<any>(`${environment.apiURL}/evaluations`, params).pipe(
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  getEvaluation(evaluation_id: number): Observable<{ data: Evaluation }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/evaluations/${evaluation_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  updateEvaluation(
    evaluation_id: number,
    data
  ): Observable<{ data: Evaluation }> {
    return this.http
      .put<any>(
        `${environment.apiURL}/evaluations/${evaluation_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  assignmentEvaluation(id: number, data): Observable<{ data: Evaluation }> {
    return this.http
      .patch<any>(
        `${environment.apiURL}/evaluations/${id}/assignment`,
        data,
        this.httpOptions
      )
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public storeEvaluation(body) {
    this.loader.open();
    return this.http
      .post(`${environment.apiURL}/evaluations`, body, this.httpOptions)
      .pipe(
        tap((e) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteEvaluation(id: number) {
    return this.http
      .delete(`${environment.apiURL}/evaluations/${id}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  userScores(user_id: number, group_id: number) {
    return this.http
      .get<any>(
        `${environment.apiURL}/evaluations/user-scores/${user_id}/${group_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
